import React from 'react';
import styles from './Accounting.module.css'; // Adjust the import based on your file structure

const Slider = ({ data }) => {
  const currentFrame = data;

  return (
    <div className={styles.sliderCont}>
      <div className={styles.sliderContainer}>
        <div className={styles.sliderContent}>
          <div style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'center' }}>

            <div className={styles.sliderText} style={{ margin: '0%' }}>
              <h2 className={styles.head11}>{currentFrame.head1}</h2>
              <h2 className={styles.head22}>{currentFrame.head2}</h2>
              <div className={styles.disCont2}><p style={{ lineHeight: '160%' }}>{currentFrame.description}</p></div>

            </div>
            <div className={styles.sliderImage}>
              <img src={currentFrame.imageSrc} alt="Slider Image" />
            </div>
          </div>

        </div>

        {/* Frames container */}
        <div className={styles.framesContainer} style={{ flexDirection: 'row-reverse', margin: '15px' }}>
          {/* Frames in rows of three */}
          <div className={styles.frameRow}>
            {/* Frame 1 */}
            <div className={styles.shapecontainer}>
              <div className={styles.numbline}>
                <div className={styles.numberingshape2}>
                  <span style={{ fontFamily: 'Cairo Light' }} className={styles.number}>١</span>
                </div>
                <hr className={styles.separator} />
              </div>
              <div className={styles.frame2}>
                <h3>{currentFrame.frame1}</h3>
              </div>
            </div>

            {/* Frame 2 */}
            <div className={styles.shapecontainer}>
              <div className={styles.numbline}>
                <div className={styles.numberingshape2}>
                  <span style={{ fontFamily: 'Cairo Light' }} className={styles.number}> ٢</span>
                </div>
                <hr className={styles.separator} />
              </div>
              <div className={styles.frame2}>
                <h3>{currentFrame.frame2}</h3>
              </div>
            </div>

            {/* Frame 3 */}
            <div className={styles.shapecontainer}>
              <div className={styles.numbline}>
                <div className={styles.numberingshape2}>
                  <span style={{ fontFamily: 'Cairo Light' }} className={styles.number}>٣</span>
                </div>
                <hr className={styles.separator} />
              </div>
              <div className={styles.frame2}>
                <h3>{currentFrame.frame3}</h3>
              </div>
            </div>
          </div>

          <div className={styles.frameRow2}>
            {/* Frame 4 */}
            <div className={styles.shapecontainer}>
              <div className={styles.numbline}>
                <div className={styles.numberingshape2}>
                  <span style={{ fontFamily: 'Cairo Light' }} className={styles.number}>٤</span>
                </div>
                <hr className={styles.separator} />
              </div>
              <div className={styles.frame2}>
                <h3>{currentFrame.frame4}</h3>
              </div>
            </div>
            {/* Frame 5 */}
            <div className={styles.shapecontainer}>
              <div className={styles.numbline}>
                <div className={styles.numberingshape2}>
                  <span style={{ fontFamily: 'Cairo Light' }} className={styles.number}>٥</span>
                </div>
                <hr className={styles.separator} />
              </div>
              <div className={styles.frame2}>
                <h3>{currentFrame.frame5}</h3>
              </div>
            </div>
            {/* Frame 6 */}
            <div className={styles.shapecontainer}>
              <div className={styles.numbline}>
                <div className={styles.numberingshape2}>
                  <span style={{ fontFamily: 'Cairo Light' }} className={styles.number}>٦</span>
                </div>
                <hr className={styles.separator} />
              </div>
              <div className={styles.frame2}>
                <h3>{currentFrame.frame6}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
