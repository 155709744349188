import React from 'react'
import styles from './GeneralServices.module.css'
import GeneralServices_ar from './GeneralServices_ar';
function ParentGeneralServices_ar() {
    return (
        <div className={styles.servicesbg}>

            <div className={styles.overlay}>
                <GeneralServices_ar />
            </div>
        </div>
    )
}

export default ParentGeneralServices_ar;