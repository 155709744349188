import React, { useEffect, useRef } from 'react';
import styles from './Landing.module.css';

function animateValue(ref, start, end, duration) {
    let startTimestamp = null;
    const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        const value = Math.floor(progress * (end - start) + start);
        if (ref.current && ref.current.innerHTML !== toArabicNumerals(value)) {
            ref.current.innerHTML = toArabicNumerals(value);
        }
        if (progress < 1) {
            window.requestAnimationFrame(step);
        }
    };
    window.requestAnimationFrame(step);
}

const toArabicNumerals = (num) => {
    return num.toString().replace(/\d/g, (d) => '٠١٢٣٤٥٦٧٨٩'[d]);
};

const NumberCounter = ({ start, end, duration, animate }) => {
    const ref = useRef(null);

    useEffect(() => {
        if (animate && ref.current) {
            animateValue(ref, start, end, duration);
        } else if (ref.current) {
            ref.current.innerHTML = toArabicNumerals(end);
        }
    }, [start, end, duration, animate]);

    return <span ref={ref}>٠</span>;
};

function CompanyNumbers({ animate = false }) {
    return (
        <div className="row justify-content-end " style={{ paddingRight: '1rem' }}>
            <div className="col-3 pr-5">
                <div className={styles.Num}>
                    <h1 style={{ display: 'flex', flexDirection: 'row-reverse', margin: '0' }}>
                        <span style={{ paddingLeft: '.5rem', fontFamily: 'Cairo Light' }} className={styles.YellowNum}>+</span>
                        <span className={styles.WhiteNum} style={{ fontFamily: 'Cairo Light' }}>
                            <NumberCounter start={0} end={14} duration={5000} animate={animate} />
                        </span>
                    </h1>
                    <h2 style={{ display: 'flex', justifyContent: 'center', fontFamily: 'Cairo Light' }}>سنة خبرة</h2>
                </div>
            </div>
            <div className="col-4">
                <div className={styles.Num}>
                    <h1 style={{ display: 'flex', flexDirection: 'row-reverse', margin: '0' }}>
                        <span style={{ paddingLeft: '.5rem', fontFamily: 'Cairo Light' }} className={styles.YellowNum}>+</span>
                        <span style={{ fontFamily: 'Cairo Light' }} className={styles.WhiteNum}>
                            <NumberCounter start={0} end={500} duration={5000} animate={animate} />
                        </span>
                    </h1>
                    <h2 style={{ display: 'flex', justifyContent: 'center', fontFamily: 'Cairo Light' }}>عميل</h2>
                </div>
            </div>
            <div className="col-3">
                <div className={styles.Num}>
                    <h1 style={{ display: 'flex', flexDirection: 'row-reverse', margin: '0' }}>
                        <span style={{ paddingLeft: '.5rem', fontFamily: 'Cairo Light' }} className={styles.YellowNum}>+</span>
                        <span style={{ fontFamily: 'Cairo Light' }} className={styles.WhiteNum}>
                            <NumberCounter start={0} end={80} duration={5000} animate={animate} />
                        </span>
                    </h1>
                    <h2 style={{ display: 'flex', justifyContent: 'center', fontFamily: 'Cairo Light' }}>شريك</h2>
                </div>
            </div>
        </div>
    );
}

export default CompanyNumbers;
