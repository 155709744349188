import React from 'react'
import styles from './GeneralServices.module.css'
import GeneralServices from './GeneralServices'
function ParentGeneralServices() {
    return (
        <div className={styles.servicesbg}>

            <div className={styles.overlay}>
                <GeneralServices />
            </div>
        </div>
    )
}

export default ParentGeneralServices;