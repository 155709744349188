import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate, Navigate } from 'react-router-dom'; // Added useNavigate
import Header from './Home/NavBar/NavBar';
import Header_ar from './Home/NavBar/NavBar_ar';
import Accounting from './Home/FinancialServices/Accounting';
import Accounting_ar from './Home/FinancialServices/Accounting_ar';
import Services from './Home/General Services/Services.jsx';
import ParentFinancialServices from './Home/FinancialServices/ParentFinancialServices';
import ParentFinancialServices_ar from './Home/FinancialServices/ParentFinancialServices_ar';
import ParentFinancialSectors_ar from './Home/FinancialServices/ParentFinancialSectors_ar';
import ParentFinancialSectors from './Home/FinancialServices/ParentFinancialSectors';
import SectorsSlider from './Home/Sectors/SectorsSlider';
import SectorsSlider_ar from './Home/Sectors/SectorsSlider_ar';
import Partners from './Home/Partners/Partners';
import Partners_ar from './Home/Partners/Partners_ar';
import Hero from './Home/Hero/Hero';
import Exporting from './Home/Exporting/Exporting'
import Exporting_ar from './Home/Exporting/Exporting_ar'
import Carousel from './Home/LandingPage/Carousel';
import Carousel_ar from './Home/LandingPage/Carousel_ar';
import FAQs from './Home/FAQs/FAQs.jsx';
import FAQs_ar from './Home/FAQs/FAQs_ar';
import Landing from './Mobile/Landing';
import Landing_ar from './Mobile/Landing_ar';
import PartnersDetails from './Home/Partners/PartnersDetails';
import PartnersDetails_ar from './Home/Partners/PartnersDetails_ar';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './App.css';
import IconsSocial from './Home/Social-icons/IconsSocial';
import IconsSocial_ar from './Home/Social-icons/IconsSocial_ar';
import Coming from './Home/Comingsoon/Coming'
import GoogleAnalytics from './GoogleAnalytics'; // Import GoogleAnalytics component
import ReactGA from 'react-ga';
import GoogleAnalytics1 from './GoogleAnalytics1.jsx';
import ParentGeneralServices from './Home/General Services/ParentGeneralServices.jsx';
import Services_ar from './Home/General Services/Services _ar';
import ParentGeneralServices_ar from './Home/General Services/ParentGeneralServices _ar';
import Generalservicesmob from './Mobile/Generalservicesmob.jsx';
// import './Mobile/partnersMobile.jsx'
function App() {
  const [lang, setLang] = useState(localStorage.getItem('lang') || 'en');

  useEffect(() => {
    // Initialize Google Analytics with your tracking ID
    ReactGA.initialize('G-GWD57NXYXS');
    // Send initial pageview
    ReactGA.pageview(window.location.pathname + window.location.search);
    // Check the language in local storage and set it if available
    let storedLang = localStorage.getItem('lang');
    if (storedLang) {
      setLang(storedLang);
    } else {
      // If no language variable is saved, default to 'ar' and save it in local storage
      setLang('ar');
      localStorage.setItem('lang', 'ar');
      storedLang = 'ar'; // Update storedLang variable after setting it
    }
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const isComingSoon = location.pathname === '/Comingsoon';

  const selectedPage = () => {
    const currentPath = location.pathname;
    // console.log(currentPath);
    if (currentPath === '/') return '/';
    if (currentPath === '/partnersDetails') return '/partnersDetails';
    if (currentPath === '/financialSectors/weserve') return '/financialSectors/weserve';
    if (currentPath.startsWith('/financialservices')) return '/financialservices';
    if (currentPath.startsWith('/financialSectors')) return '/financialSectors';
    if (currentPath.startsWith('/partners')) return '/partners';
    if (currentPath === '/generalservices') return '/generalservices';
    if (currentPath.startsWith('/generalservices')) return '/generalservices';
    if (currentPath.startsWith('/financialservices')) return '/financialservices';

    // if (currentPath === '/Products') return '/Products';
    if (currentPath === '/partners') return '/partners';
    if (currentPath === '/Home') return '/Home';
    if (currentPath === '/Exporting') return '/Exporting';
    if (currentPath === '/FAQs') return '/FAQs';
    return '/';
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024); // Initial state based on viewport width

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024); // Update state on window resize
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize); // Cleanup
    };
  }, []);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const pageid = params.get("pageid");
    if (pageid) {
      navigate(pageid); // Redirect to the page specified in pageid parameter
    }
    // www.banknoteconsult.com/?pageid=/partners
  }, [location.search, navigate]);


  if (isComingSoon) {
    return (
      <Routes>
        <Route path='/Comingsoon' element={<Coming />} />

      </Routes>

    )

  }

  else if (isMobile) {
    return (
      <div className='app'>
        <GoogleAnalytics trackingID="AW-16524439816" /> {/* Add GoogleAnalytics component here */}
        <GoogleAnalytics1 />
        {lang === 'en' ? (
          <Header selectedPage={selectedPage()} />
        ) : (
          <Header_ar selectedPage={selectedPage()} />
        )}

        <Routes>
          {lang === 'en' ? (
            <>
              <Route path='/' element={<Landing />} />
              <Route path='/financialservices' element={<Landing />} />
              <Route path='/generalservices' element={<Landing />} />
              <Route path='/financialservices/aas' element={<Accounting />} />
              <Route path='/generalservices/servicestypes' element={<Services />} />
              <Route path='/financialSectors' element={<Landing />} />
              <Route path='/financialSectors/weserve' element={<SectorsSlider />} />
              <Route path='/partners' element={<Landing />} />
              <Route path='/PartnersDetails' element={<Landing />} />
              <Route path='/Exporting' element={<Landing />} />
              <Route path='/FAQs' element={<Landing />} />
              <Route path='/partnersMobile' element={<Landing />} />
              <Route path='/Home' element={<Landing />} />
              <Route path="/contactus" element={<Navigate to="/FAQs" state={{ setTriggerScroll: true }} />} />
            </>
          ) : (
            <>
              <Route path='/' element={<Landing_ar />} />
              <Route path='/financialservices' element={<Landing_ar />} />
              <Route path='/generalservices' element={<Landing_ar />} />
              <Route path='/generalservices/servicestypes' element={<Services_ar />} />
              <Route path='/financialservices/aas' element={<Accounting_ar />} />
              <Route path='/financialSectors' element={<Landing_ar />} />
              <Route path='/financialSectors/weserve' element={<SectorsSlider_ar />} />
              <Route path='/partners' element={<Landing_ar />} />
              <Route path='/PartnersDetails' element={<Landing_ar />} />
              <Route path='/Exporting' element={<Landing_ar />} />
              <Route path='/FAQs' element={<Landing_ar />} />
              <Route path='/partnersMobile' element={<Landing_ar />} />
              <Route path='/Home' element={<Landing_ar />} />
              <Route path="/contactus" element={<Navigate to="/FAQs" state={{ setTriggerScroll: true }} />} />
            </>
          )
          }
        </Routes >
      </div >
    )
  }
  else {
    return (
      <div className='app'>
        <GoogleAnalytics trackingID="AW-16524439816" /> {/* Add GoogleAnalytics component here */}
        <GoogleAnalytics1 />

        {
          lang === 'en' ? (
            <>
              <IconsSocial />
              <Header selectedPage={selectedPage()} />
            </>
          ) : (
            <>
              <IconsSocial_ar />
              <Header_ar selectedPage={selectedPage()} />
            </>
          )
        }
        <RoutesWithTransition lang={lang} />
      </div >
    );
  }
}

function RoutesWithTransition({ lang }) {
  const location = useLocation();
  // const location =useLocation(location)  
  return (
    <TransitionGroup>
      <CSSTransition key={location.key} timeout={{ enter: 1000, exit: 1000 }} classNames={{
        enter: 'slide-enter',
        enterActive: 'slide-enter-active',
        exit: 'slide-exit',
        exitActive: 'slide-exit-active',
      }}
        unmountOnExit>
        <Routes1 location={location} lang={lang} />
      </CSSTransition>
    </TransitionGroup>
  );
}

function Routes1({ location, lang }) {
  return (
    <Routes location={location}>
      {lang === 'en' && (
        <>
          <Route path='/' element={<Carousel />} />
          <Route path='/financialservices' element={<ParentFinancialServices />} />
          <Route path='/generalservices' element={<ParentGeneralServices />} />
          <Route path='/generalservices/servicestypes' element={<Services />} />
          <Route path='/financialservices/aas' element={<Accounting />} />
          <Route path='/financialSectors' element={<ParentFinancialSectors />} />
          <Route path='/financialSectors/weserve' element={<SectorsSlider />} />
          <Route path='/partners' element={<Partners />} />
          <Route path='/Hero' element={<Hero />} />
          <Route path='/PartnersDetails' element={<PartnersDetails />} />
          <Route path='/Exporting' element={<Exporting />} />
          <Route path='/FAQs' element={<FAQs />} />
          <Route path="/contactus" element={<Navigate to="/FAQs" state={{ setTriggerScroll: true }} />} />
        </>
      )
      }
      {
        lang === 'ar' && (
          <>
            <Route path='/' element={<Carousel_ar />} />
            <Route path='/financialservices' element={<ParentFinancialServices_ar />} />
            <Route path='/generalservices' element={<ParentGeneralServices_ar />} />
            <Route path='/generalservices/servicestypes' element={<Services_ar />} />
            <Route path='/financialservices/aas' element={<Accounting_ar />} />
            <Route path='/financialSectors' element={<ParentFinancialSectors_ar />} />
            <Route path='/financialSectors/weserve' element={<SectorsSlider_ar />} />
            <Route path='/partners' element={<Partners_ar />} />
            <Route path='/Hero' element={<Hero />} />
            <Route path='/PartnersDetails' element={<PartnersDetails_ar />} />
            <Route path='/Exporting' element={<Exporting_ar />} />
            <Route path='/FAQs' element={<FAQs_ar />} />
            <Route path="/contactus" element={<Navigate to="/FAQs" state={{ setTriggerScroll: true }} />} />

          </>
        )
      }
    </Routes >
  );
}
export default App;