import React, { useState, useEffect } from 'react';
import styles from './Footer.module.css';
import { useNavigate, useLocation } from 'react-router-dom';

function BottomButton() {
    const [showButton, setShowButton] = useState(true); // Initialize to true
    const navigate = useNavigate();
    useEffect(() => {
        const contactForm = document.getElementById('recapatcha');

        const handleIntersect = (entries) => {
            entries.forEach((entry) => {
                // If contactForm is intersecting (i.e., visible)
                // set showButton to false, otherwise set it to true
                setShowButton(!entry.isIntersecting);
            });
        };

        // Create an intersection observer
        const observer = new IntersectionObserver(handleIntersect, {
            root: null, // Use the viewport as the root
            threshold: 0.1, // Trigger when at least 50% of the contactForm is visible
        });

        // Start observing the contactForm element
        if (contactForm) {
            observer.observe(contactForm);
        }

        // Cleanup function to disconnect the observer when component unmounts
        return () => {
            if (contactForm) {
                observer.unobserve(contactForm);
            }
        };
    }, []);

    const scrollToContactForm = () => {
        const contactForm = document.getElementById('contactFormMob2');

        if (window.location.pathname !== '/') {
            // Navigate to FAQs page without reloading
            navigate('/', { state: { setTriggerScroll: true } });
            if (contactForm) {
                contactForm.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }

        if (contactForm) {
            contactForm.scrollIntoView({
                behavior: 'smooth',
            });
        }
    };
    const [isOpen, setIsOpen] = useState(false);

    const toggleIcons = () => {
        setIsOpen(!isOpen);
    };
    return (
        <>
            <div className={styles.bottombuttoncontainer}>
                {showButton && (
                    <button className={styles.button} onClick={scrollToContactForm}>
                        Contact Us
                    </button>
                )}

            </div>
            {showButton && (
                <div className={styles.socialMobCont}>

                    <div className={styles.socialMob}>
                        <img src="https://banknoteconsult.com/socialMob/telegram2.svg" alt="" onClick={() => window.open('https://t.me/Banknote_financial_consulting', '_blank')} />
                        <img src="https://banknoteconsult.com/socialMob/messanger2.svg" alt="" onClick={() => window.open('https://m.me/Banknoteeg', '_blank')} />
                        <img src="https://banknoteconsult.com/socialMob/whatsapp2.svg" alt="" onClick={() => window.open('https://api.whatsapp.com/send?phone=201000046940', '_blank')} />
                        <img src="https://banknoteconsult.com/socialMob/call2.svg" alt="" onClick={() => window.open('tel:+201000046940', '_self')} />

                    </div>
                </div>
            )}
            {/* <div className={styles.socialMobCont}>
                <div className={styles.socialMob}>
                    <img

                        src="socialMob/down.svg"
                        alt="Toggle Icons"
                        onClick={toggleIcons}
                        className={styles.contactusFloating}
                        style={{ display: isOpen ? 'block' : 'none' }}
                    />
                    <img
                        src="socialMob/contactus.svg"
                        alt="Toggle Icons"
                        onClick={toggleIcons}
                        className={styles.contactusFloating}
                        style={{ display: isOpen ? 'none' : 'block' }}
                    />
                    <img
                        style={{ zIndex: 99 }}

                        src="socialMob/telegram.svg"
                        alt="Telegram"
                        className={isOpen ? styles.visible : styles.hidden}
                    />
                    <img
                        style={{ zIndex: 99 }}

                        src="socialMob/messanger.svg"
                        alt="Messenger"
                        className={isOpen ? styles.visible : styles.hidden}
                    />
                    <img
                        style={{ zIndex: 99 }}

                        src="socialMob/whatsapp.svg"
                        alt="WhatsApp"
                        className={isOpen ? styles.visible : styles.hidden}
                    />
                    <img
                        style={{ zIndex: 99 }}

                        src="socialMob/call.svg"
                        alt="Call"
                        className={isOpen ? styles.visible : styles.hidden}
                    />
                </div>
            </div> */}

        </>
    );
}

export default BottomButton;
