import React from 'react';
import styles from './IconsSocial.module.css'; // Correct import statement

function IconsSocial() {
  const handleMouseOver = (event) => {
    event.target.src = event.target.getAttribute('data-hover');
  };

  const handleMouseOut = (event) => {
    event.target.src = event.target.getAttribute('data-normal');
  };

  return (
    <div className={styles.socialButtons}>

      <a  >
        <img style={{ width: '40px' }}
          src="/socialicons/call.svg"
          alt="Call"
          data-hover="/socialicons/call_hover.svg"
          data-normal="/socialicons/call.svg"
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onClick={() => window.open('tel:+201000046940', '_self')}
        />
      </a>
      <a href="https://api.whatsapp.com/send?phone=201000046940">
        <img style={{ width: '32px' }}
          src="/socialicons/Whatsupp.svg"
          alt="Whatsapp"
          data-hover="/socialicons/Whatsup_hover.svg"
          data-normal="/socialicons/Whatsupp.svg"
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        />
      </a>
      <a href="https://t.me/Banknote_financial_consulting">
        <img style={{ width: '40px' }}
          src="/socialicons/telegram.svg"
          alt="telegram"
          data-hover="/socialicons/telegram_hover.svg"
          data-normal="/socialicons/telegram.svg"
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        />
      </a>
      <a href="https://www.facebook.com/Banknoteeg">
        <img style={{ width: '32px' }}
          src="/socialicons/Facebook.svg"
          alt="Facebook"
          data-hover="/socialicons/Facebook_hover.svg"
          data-normal="/socialicons/Facebook.svg"
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        />
      </a>
      <a href="https://www.instagram.com/banknoteeg/">
        <img style={{ width: '32px' }}
          src="/socialicons/Insta.svg"
          alt="Instagram"
          data-hover="/socialicons/Insta_hover.svg"
          data-normal="/socialicons/Insta.svg"
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        />
      </a>
    </div>
  );
}

export default IconsSocial;
