import React, { useState, useEffect } from 'react'
import styles from './GeneralServices.module.css';
import IconsSocial from '../Social-icons/IconsSocial';
import { useNavigate } from 'react-router-dom';

function GeneralServices_ar() {
    const navigate = useNavigate(); // Access the history object

    const handleNavigation = (pageNumber) => {

        navigate(`/generalservices/servicestypes?pagenumb=${pageNumber}`);
    };
    const [animate, setAnimate] = useState(false);
    useEffect(() => {
        // Trigger animation whenever the 'animate' prop changes

        setAnimate(true);

    }, [setAnimate]);
    return (

        <div className={styles.section2} style={{ gap: '1.7%', flexDirection: 'row-reverse' }} >


            <div className={styles.leftCont}>
                <div style={{ display: 'flex', alignItems: 'center', height: '33vh' }}>
                    <div className={styles.textContent}>
                        <h1 className={styles.mainTitleWhite2}>الخدمات</h1>
                        <h1 className={styles.mainTitleYellow2}>العامه</h1>
                    </div></div>

                <div className={styles.rightBottomCont} style={{ flexDirection: 'row-reverse' }}>

                    
                    <div className={` ${styles.serBox2}`} onClick={() => handleNavigation(5)} >

                        <div>
                        
                        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_6458_10170)">
                                <path d="M60 58.1633H0V60H60V58.1633Z" fill="white"/>
                                <path d="M2.78949 44.095H1.87109V57.2097H3.70777V45.9812C7.94422 46.4402 11.2529 50.0386 11.2529 54.395V57.2095H13.0896V54.3952C13.0897 48.7156 8.4691 44.095 2.78949 44.095Z" fill="white"/>
                                <path d="M5.51016 41.2654H0V43.1021H5.51016V41.2654Z" fill="white"/>
                                <path d="M1.87109 19.7023V40.3223H3.70777V21.5391H16.8818V26.2498C16.8818 32.9857 11.4017 38.4659 4.66566 38.4659V40.3025C12.4145 40.3024 18.7187 33.9984 18.7187 26.2496V19.7021L1.87109 19.7023Z" fill="white"/>
                                <path d="M7.46949 23.5103H5.63281V36.4898H7.46949V23.5103Z" fill="white"/>
                                <path d="M7.46949 48.7346H5.63281V57.1836H7.46949V48.7346Z" fill="white"/>
                                <path d="M57.2025 44.095C51.523 44.095 46.9023 48.7156 46.9023 54.3952V57.2097H48.739V54.3952C48.739 50.0386 52.0476 46.4404 56.2841 45.9813V57.2098H58.1208V57.2097V44.095H57.2025Z" fill="white"/>
                                <path d="M59.9984 41.2654H54.4883V43.1021H59.9984V41.2654Z" fill="white"/>
                                <path d="M41.2734 19.7024V26.2499C41.2734 33.9986 47.5774 40.3028 55.3263 40.3028V38.4661C48.5904 38.4661 43.1102 32.9861 43.1102 26.25V21.5393H56.2841V40.3226H58.1209V19.7024H41.2734Z" fill="white"/>
                                <path d="M54.3679 23.5103H52.5312V36.4898H54.3679V23.5103Z" fill="white"/>
                                <path d="M54.3679 48.7346H52.5312V57.1836H54.3679V48.7346Z" fill="white"/>
                                <path d="M30.2134 0H27.8891L0.980469 14.4422V18.7346H59.0213V14.4245L30.2134 0ZM28.3507 1.83668H29.7792L33.4474 3.67336H24.9286L28.3507 1.83668ZM57.1845 16.898H2.81727V15.9184H25.3479V14.0817H5.53648L21.5519 5.4859V5.51027H37.1157L54.2342 14.0817H34.6539V15.9184H57.1845V16.898Z" fill="white"/>
                                <path d="M32.8162 14.0815H27.1836V15.9182H32.8162V14.0815Z" fill="white"/>
                                <path d="M19.5938 19.7144V21.551H20.5733V28.1632H26.3284V21.551H27.1855V28.1632H32.8181V21.551H33.6752V28.1632H39.4303V21.551H40.4099V19.7144H19.5938ZM24.4917 26.3265H22.4101V21.551H24.4917V26.3265ZM30.9814 26.3265H29.0223V21.551H30.9814V26.3265ZM37.5938 26.3265H35.512V21.551H37.5936V26.3265H37.5938Z" fill="white"/>
                                <path d="M41.1156 35.4456C39.7019 34.0319 37.822 33.2532 35.8225 33.2532C33.823 33.2532 31.9432 34.0319 30.5294 35.4456L30.5153 35.4596L30.5014 35.4456C29.0876 34.0319 27.2077 33.2532 25.2082 33.2532C23.2088 33.2532 21.329 34.0319 19.9151 35.4456C18.5014 36.8594 17.7227 38.7393 17.7227 40.7388C17.7227 42.7382 18.5014 44.618 19.9151 46.0319L30.5154 56.6322L41.1156 46.0319C42.5294 44.6181 43.3081 42.7382 43.3081 40.7388C43.3081 38.7392 42.5295 36.8594 41.1156 35.4456ZM39.8171 44.7332L30.5155 54.0346L21.2141 44.7332C19.0114 42.5306 19.0114 38.9468 21.2139 36.7444C22.3152 35.6431 23.7617 35.0925 25.2084 35.0925C26.6548 35.0925 28.1015 35.6432 29.2028 36.7445L30.5155 38.0572L31.8283 36.7444C32.8952 35.6775 34.3137 35.0899 35.8227 35.0899C37.3315 35.0899 38.7501 35.6773 39.8172 36.7444C40.8841 37.8113 41.4718 39.2299 41.4718 40.7389C41.4716 42.2477 40.8841 43.6662 39.8171 44.7332Z" fill="white"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_6458_10170">
                                <rect width="60" height="60" fill="white"/>
                                </clipPath>
                                </defs>
                                </svg>


                        </div>
                        <div className={styles.boxTitle2}>
                            <h5>خدمات الأفراح
                            </h5>
                        

                </div>
                    </div>

                    <div className={` ${styles.serBox2}`} onClick={() => handleNavigation(4)} >

                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                                <g clip-path="url(#clip0_6242_825)">
                                    <path d="M36.25 16.2501L43.75 9.00006C44.7114 8.03865 46.0154 7.49854 47.375 7.49854C48.7346 7.49854 50.0386 8.03865 51 9.00006C51.9614 9.96147 52.5015 11.2654 52.5015 12.6251C52.5015 13.9847 51.9614 15.2886 51 16.2501L43.75 23.7501L50 42.5001L43.75 48.8751L35 32.5001L27.5 40.0001V47.5001L22.5 52.5001L18.75 41.2501L7.5 37.5001L12.5 32.5001H20L27.5 25.0001L11.25 16.2501L17.5 10.0001L36.25 16.2501Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1058_130">
                                        <rect width="60" height="60" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div className={styles.boxTitle2}>
                            <h5>
                                خدمات النقل والرحلات
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.rightCont} style={{ flexDirection: 'column' }}>

                <div className={styles.rightTopCont} style={{marginRight: '3rem'}}>
                    <div className={` ${styles.serBox2}`} onClick={() => handleNavigation(1)}  >
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="61" height="61" viewBox="0 0 61 61" fill="none">
                                <g clip-path="url(#clip0_6250_657)">
                                    <path d="M13 43C13 44.3261 13.5268 45.5979 14.4645 46.5355C15.4021 47.4732 16.6739 48 18 48C19.3261 48 20.5979 47.4732 21.5355 46.5355C22.4732 45.5979 23 44.3261 23 43C23 41.6739 22.4732 40.4021 21.5355 39.4645C20.5979 38.5268 19.3261 38 18 38C16.6739 38 15.4021 38.5268 14.4645 39.4645C13.5268 40.4021 13 41.6739 13 43Z" stroke="#FEFCFA" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M38 43C38 44.3261 38.5268 45.5979 39.4645 46.5355C40.4021 47.4732 41.6739 48 43 48C44.3261 48 45.5979 47.4732 46.5355 46.5355C47.4732 45.5979 48 44.3261 48 43C48 41.6739 47.4732 40.4021 46.5355 39.4645C45.5979 38.5268 44.3261 38 43 38C41.6739 38 40.4021 38.5268 39.4645 39.4645C38.5268 40.4021 38 41.6739 38 43Z" stroke="#FEFCFA" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M13 43H8V28M8 28L13 15.5H35.5L45.5 28M8 28H45.5M45.5 28H48C49.3261 28 50.5979 28.5268 51.5355 29.4645C52.4732 30.4021 53 31.6739 53 33V43H48M38 43H23M30.5 28V15.5" stroke="#FEFCFA" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_6250_657">
                                        <rect width="60" height="60" fill="white" transform="translate(0.5 0.5)" />
                                    </clipPath>
                                </defs>
                            </svg>

                        </div>
                        <div className={styles.boxTitle2}>
                            <h5> خدمات السيارات </h5>
                        </div>
                    </div>
                    <div className={` ${styles.serBox2}`} onClick={() => handleNavigation(2)} >
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="61" height="60" viewBox="0 0 61 60" fill="none">
                                <g clip-path="url(#clip0_6250_801)">
                                    <path d="M13 30H8L30.5 7.5L53 30H48" stroke="#FEFCFA" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M13 30V47.5C13 48.8261 13.5268 50.0979 14.4645 51.0355C15.4021 51.9732 16.6739 52.5 18 52.5H43C44.3261 52.5 45.5978 51.9732 46.5355 51.0355C47.4732 50.0979 48 48.8261 48 47.5V30" stroke="#FEFCFA" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M25.5 30H35.5V40H25.5V30Z" stroke="#FEFCFA" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_6250_801">
                                        <rect width="60" height="60" fill="white" transform="translate(0.5)" />
                                    </clipPath>
                                </defs>
                            </svg>

                        </div>
                        <div className={styles.boxTitle2}>
                            <h5>
                                خدمات المنزل
                            </h5>
                        </div>
                    </div>
                </div>
                <div className={styles.rightBottomCont} style={{ marginRight: '1.5rem',flexDirection: 'row-reverse' }}>
                    <div className={` ${styles.serBox2}`} onClick={() => handleNavigation(3)} >

                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="61" height="60" viewBox="0 0 61 60" fill="none">
                                <g clip-path="url(#clip0_6250_2880)">
                                    <path d="M33 22.5C33 21.837 33.2634 21.2011 33.7322 20.7322C34.2011 20.2634 34.837 20 35.5 20H50.5C51.163 20 51.7989 20.2634 52.2678 20.7322C52.7366 21.2011 53 21.837 53 22.5V47.5C53 48.163 52.7366 48.7989 52.2678 49.2678C51.7989 49.7366 51.163 50 50.5 50H35.5C34.837 50 34.2011 49.7366 33.7322 49.2678C33.2634 48.7989 33 48.163 33 47.5V22.5Z" stroke="#FEFCFA" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M45.5 20V12.5C45.5 11.837 45.2366 11.2011 44.7678 10.7322C44.2989 10.2634 43.663 10 43 10H10.5C9.83696 10 9.20107 10.2634 8.73223 10.7322C8.26339 11.2011 8 11.837 8 12.5V42.5C8 43.163 8.26339 43.7989 8.73223 44.2678C9.20107 44.7366 9.83696 45 10.5 45H33" stroke="#FEFCFA" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M40.5 22.5H45.5" stroke="#FEFCFA" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_6250_2880">
                                        <rect width="60" height="60" fill="white" transform="translate(0.5)" />
                                    </clipPath>
                                </defs>
                            </svg>

                        </div>
                        <div className={styles.boxTitle2}>
                            <h5>خدمات التكنولوجيا
                            </h5>
                        </div>

                    </div>
                    <div style={{ alignItems: 'center', display: 'flex' }}>
                        <div>
                            <span className={styles.NumYellow}> + </span> <span className={styles.NumWhite}>المزيد</span>

                        </div>
                    </div>

                </div>
            </div>
        </div>


    )
}

export default GeneralServices_ar;