import React, { useState, useEffect, useRef } from 'react';
import Slider_ar from './Slider_ar';
import styles from './Accounting.module.css';
import { useLocation } from 'react-router-dom';
import '../../index.css';
import Footer from '../Footer/Footer_ar'
import BottomButton from '../Footer/BottomButton_ar'
import debounce from 'lodash/debounce'; // Import debounce function
const framesData = [
  {
    head1: 'تسهيلات',
    head2: 'بنكية',
    description: 'تتعامل شركة بنكنوت مع أكثر من 27 بنكًا حكوميًا وخاصًا، مما يضمن توفير  مجموعة واسعة من الخيارات التمويلية المناسبة تمامًا لاحتياجات شركتك',
    imageSrc: '/Banking\ Facilities\ Pic.png',
    frame1: 'توصيات للحصول على تمويل من مصادر متنوعة',
    frame2: 'خطط عمل مخصصة',
    frame3: 'إعادة هيكلة الشركات الحالية',
    frame4: 'تقييم الشركة',
    frame5: 'توقعات مالية دقيقة',
    frame6: 'دعم التحويلات'
  },
  {
    head1: 'خدمات المحاسبة',
    head2: 'والإستشارات',
    description: 'يقدم فريق شركة بنكنوت الخبراء إرشادًا خلال التحديات الصعبة والمعقدة المتعلقة بالمعاملات والقرارات المالية ',
    imageSrc: '/AAS.png',
    frame1: 'تدقيق الوثائق المالية',
    frame2: 'دعم العمليات المالية',
    frame3: 'إدارة المخاطر',
    frame4: 'استشارات المحاسبة التقنية',
    frame5: 'ضمان الامتثال التنظيمي',
    frame6: 'تفسير معايير المحاسبة'
  },
  {
    head1: 'التخطيط',
    head2: 'المالي',
    description: 'نقدم مجموعة شاملة من الحلول المركزة على إدارة وتحسين مواردك المالية ',
    imageSrc: '/Financial\ planning\ pic.png',
    frame1: 'استراتيجيات التخطيط والتوفير',
    frame2: 'تخطيط الاستثمار',
    frame3: 'تخطيط التأمين',
    frame4: 'إدارة الديون',
    frame5: 'تخطيط الضرائب',
    frame6: 'إدارة التدفق النقدي'
  },
  {
    head1: 'الضمان',
    head2: 'واستشارات',
    description: ' نحن نضمن  لعملائنا بناء أسس مالية قوية مدعومة بامتثال صارم للمتطلبات القانونية ',
    imageSrc: '/Assurance\ &\ Advisory\ Pic.png',
    frame1: 'معايير المحاسبة العالمية',
    frame2: 'إعداد البيانات المالية',
    frame3: 'تقييم الرقابة الداخلية',
    frame4: 'ضمان الامتثال التنظيمي',
    frame5: 'خدمات تقدير المخاطر التجارية',
    frame6: 'إدارة المخاطر'
  },

];


const Accounting_ar = () => {
  const footerIconsRef = useRef(null);
  const [showBottomButton, setShowBottomButton] = useState(true); // State to manage button visibility

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialPageNumber = queryParams.get('pagenumb') || 1;

  const [currentSlide, setCurrentSlide] = useState(initialPageNumber - 1);
  const totalSlides = framesData.length;

  const slideWidth = 100; // Each slide takes 100% of the width

  const handleNextClick = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
  };

  const handlePrevClick = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + totalSlides) % totalSlides);
  };

  const slidesrowStyle = {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    width: `${totalSlides * slideWidth}%`,
    transform: `translateX(-${currentSlide * (100 / totalSlides)}%)`,
    transition: 'transform 0.5s ease-in-out',
  };

  useEffect(() => {
    const handleScroll = debounce((event) => {

      if (event.deltaY > 0) {
        // Scrolling down, go to the next page
        handleNextClick();
      } else if (event.deltaY < 0) {
        // Scrolling up, go to the previous page (optional)
        handlePrevClick();
      }

    }, 200); // Adjust the debounce delay as needed


    // Add the event listener to handle scroll events
    window.addEventListener('wheel', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, [currentSlide, handleNextClick, handlePrevClick]);


  useEffect(() => {
    const footerIcons = document.getElementById('footericons');
    const observer = new IntersectionObserver(handleIntersect, {
      root: null,
      threshold: 0.1,
    });
    if (footerIcons) {
      observer.observe(footerIcons);
    }

    return () => {
      if (footerIcons) {
        observer.unobserve(footerIcons);
      }
    };
  }, []);

  const handleIntersect = (entries) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        setShowBottomButton(true); // If intersecting, show the button
      } else {
        setShowBottomButton(false); // If not intersecting, hide the button
      }
    });
  };
  return (
    <div>
      <div className='overlay' style={{}}></div>
      <div className={styles.mobPageContainer} style={{ overflowY: 'auto', maxHeight: '100vh', backgroundSize: 'cover', backgroundAttachment: 'fixed' }}>
        <div className={styles.submobPageContainer}>
          <div className={styles.mobTitle2}>
            <h1>
              الخدمات  <span style={{ color: '#EBD5A8' }}>المالية</span>
            </h1>
          </div>
          {framesData.map((data, index) => (
            <div key={index} style={{ marginBottom: '15%' }} >
              <div className={styles.mobimageCont}>
                <img src={data.imageSrc} alt="" />
              </div>
              <div className={styles.mobText}>
                <h2 className={styles.mobHead12}>{data.head1}</h2>
                <h2 className={styles.mobHead22}>{data.head2}</h2>
                <div className={styles.mobDisCont2}><p>{data.description}</p></div>
              </div>
              {Object.entries(data).slice(3).filter(([key]) => key.startsWith('frame')).map(([key, value], frameIndex) => (
                <div key={frameIndex} className={styles.mobshapecontainer}>
                  <div className={styles.mobnumbline2}>
                    <div className={styles.mobnumberingshape}>
                      <span style={{ fontFamily: 'Cairo Light' }} className={styles.mobnumber}>
                        {(frameIndex + 1).toLocaleString('ar')}
                      </span>
                    </div>
                    <hr className={styles.mobseparator2} />
                  </div>
                  <div className={styles.mobframe2}>
                    <h3>{value}</h3>
                  </div>
                </div>
              ))}

            </div>
          ))}

        </div>
        <div style={{ zIndex: '99' }}>  <Footer></Footer></div>

        {showBottomButton && <BottomButton ref={footerIconsRef}></BottomButton>}

      </div>



      <div className={styles.pageContainer}>
        <div className='overlay' style={{ display: 'block', position: 'fixed' }}>
          <div style={slidesrowStyle}>
            {framesData.map((data, index) => (
              <div key={index} style={{ width: `${slideWidth}%` }}>
                <Slider_ar data={data} />
              </div>
            ))}
          </div>
          <div className={styles.slidersbuttons} style={{ marginLeft: '3%', gap: '0' }}>
            <button className={styles.leftarrow} onClick={handleNextClick}></button>
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              {[...Array(totalSlides).keys()].map((pageNum) => (
                <button
                  style={{ fontFamily: 'Cairo Light', lineHeight: '10%' }}
                  key={pageNum}
                  className={`${styles.pageNumber} ${pageNum === currentSlide ? styles.selectedPage : ''
                    }`}
                  onClick={() => setCurrentSlide(pageNum)}
                >
                  {(pageNum + 1).toLocaleString('ar-EG')}
                </button>
              ))}
            </div>


            <button className={styles.rightarrow} onClick={handlePrevClick}></button>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Accounting_ar;

// http://localhost:3000/financialservices/aas?pagenumb=2
