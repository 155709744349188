import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './partnersMobile.module.css';


const MobModal = ({ partner, onClose }) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            const modalContent = document.querySelector('.modal-content');
            if (modalContent && !modalContent.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    return (
        <div className={styles.modaloverlay}>
            <div className='modal-content' style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <div className={`${styles.modalmob}`}>
                    <Slider2 img1={partner['Image1Url']} img2={partner['Image2Url']} video={partner['videoUrl']} videoThumbnail={partner['Image3Url']} />                    </div>
            </div>
        </div>

    );
};

function Card({ logoImageUrl, Image1Url, Image2Url, Image3Url, partnerName }) {
    return (
        <div className={styles.Card}>
            <div className={styles.logoContainer}>
                <img src={logoImageUrl} alt="Logo" className={styles.logoImage} />
            </div>
            <div className={styles.namePartner}>{partnerName}</div>
            {/* <div className={styles.cardDownImgs}>
                <img className={styles.cardImg1} src={Image1Url} alt="partner image 1" />
                <img className={styles.cardFrame1} src='/Partners/bgImgs.svg' alt="frame 1" />
                <img className={styles.cardImg2} src={Image2Url} alt="partner image 2" />
                <img className={styles.cardFrame2} src='/Partners/bgImgs.svg' alt="frame 2" />
                <img className={styles.cardImg3} src={Image3Url} alt="partner image 3" />
            </div> */}
        </div>
    );
}

const Slider2 = ({ img1, img2, video, videoThumbnail }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleClick = () => {
        // If on the last slide, loop back to the first slide
        if (currentSlide === slides.length - 1) {
            setCurrentSlide(0);
        } else {
            setCurrentSlide(currentSlide + 1);
        }
    };

    const handlePrev = () => {
        // If on the first slide, loop to the last slide
        if (currentSlide === 0) {
            setCurrentSlide(slides.length - 1);
        } else {
            setCurrentSlide(currentSlide - 1);
        }
    };
    // Define your slides array
    const slides = [
        { type: 'img', content: <img src={img1} alt="1" onClick={handleClick} style={{ width: '100%', height: '25vh' }} />, thumbnail: img1 },
        { type: 'img', content: <img src={img2} alt="2" onClick={handleClick} style={{ width: '100%', height: '25vh' }} />, thumbnail: img2 },
        { type: 'video', content: <video alt="3" style={{ width: '100%', height: '25vh' }} autoPlay loop muted controls><source src={video} type="video/mp4" /></video>, thumbnail: videoThumbnail }
    ];

    // Render only the current slide
    return (
        <div>

            {slides[currentSlide].content}

            {/* Navigation buttons */}
            <div style={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                {/* <button style={{ color: 'blanchedalmond' }} onClick={handlePrev}>Prev</button>
                <button style={{ color: 'blanchedalmond' }} onClick={handleClick}>Next</button> */}
            </div>

            {/* Thumbnails */}
            <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px', }}>
                {slides.map((slide, index) => (
                    <img
                        key={index}
                        src={slide.thumbnail}
                        alt={`Thumbnail ${index + 1}`}
                        style={{
                            width: '30%',
                            marginRight: '5px',
                            border: index === currentSlide ? '2px solid blue' : 'none',
                            filter: 'brightness(50%)' // Adjust the brightness value as needed (e.g., 50%)
                        }}
                        onClick={() => setCurrentSlide(index)}
                    />
                ))}
            </div>
        </div>
    );
};
function PartnersMobile() {
    const partners = [
        {
            partnerName: 'Toy & Joy',
            buttonNumber: 1,
            logoImageUrl: '/Partners/Toy & Joy/logo.png',
            image1Src: '/Partners/Toy & Joy/1.jpg',
            image2Src: '/Partners/Toy & Joy/2.jpg',
            videoSrc: '/Partners/Toy & Joy/video.mp4',
            videoThumbnail: '/Partners/Toy & Joy/videoThumbnail.jpg',
            info: "Founded in 2011, Toy & Joy specializes in selling children's toys and educational materials such as number and letter learning toys.The company has multiple branches."
        }
        ,
        {
            partnerName: '2M Group',
            buttonNumber: 2,
            logoImageUrl: '/Partners/2M Group/logo.png',
            image1Src: '/Partners/2M Group/1.jpg',
            image2Src: '/Partners/2M Group/2.jpg',
            videoSrc: '/Partners/2M Group/video.mp4',
            videoThumbnail: '/Partners/2M Group/videoThumbnail.jpg',
            info: 'Founded in 2011, 2M Group is a company specializing in printing services and related supplies. It serves various clients, including Art Media and Media Print.'
        }
        ,
        {
            partnerName: 'Al Eman',
            buttonNumber: 3,
            logoImageUrl: '/Partners/Al Eman/logo.png',
            image1Src: '/Partners/Al Eman/1.jpg',
            image2Src: '/Partners/Al Eman/2.jpg',
            videoSrc: '/Partners/Al Eman/video.mp4',
            videoThumbnail: '/Partners/Al Eman/videoThumbnail.jpg',
            info: 'Established in 2017, Al Eman Plastic Factory is one of the leading manufacturers in plastic blow molding, producing barrels, jerry cans, and the latest types of chairs and tables in various sizes, shapes, cultures, weights, colors, and international specifications, serving all industries.'
        }
        ,
        {
            partnerName: 'Merco Market',
            buttonNumber: 4,
            logoImageUrl: '/Partners/Merco Market/logo.png',
            image1Src: '/Partners/Merco Market/1.jpg',
            image2Src: '/Partners/Merco Market/2.jpg',
            videoSrc: '/Partners/Merco Market/video.mp4',
            videoThumbnail: '/Partners/Merco Market/videoThumbnail.jpg',
            info: 'Founded in 2016, Merco Market specializes in manufacturing feed additives and organic fertilizers. Its key clients include Al Madina Trading Company and Venus Company. The company holds all necessary licenses.'
        }
        ,
        {
            partnerName: 'Al Masrya Foam',
            buttonNumber: 5,
            logoImageUrl: '/Partners/Al Masrya Foam/logo.png',
            image1Src: '/Partners/Al Masrya Foam/1.jpg',
            image2Src: '/Partners/Al Masrya Foam/2.jpg',
            videoSrc: '/Partners/Al Masrya Foam/video.mp4',
            videoThumbnail: '/Partners/Al Masrya Foam/videoThumbnail.jpg',
            info: 'Established in 2016, Al Masrya Foam specializes in manufacturing and distributing industrial sponge products in various densities. The company employs numerous technicians to produce high-quality mattresses. They have also launched another brand under Al Masrya Foam called "Cloud Bed," which offers high-quality mattresses. The factory is equipped with advanced machinery and equipment for producing the best materials.'
        }
        ,
        {
            partnerName: 'Golden Power',
            buttonNumber: 6,
            logoImageUrl: '/Partners/Golden Power/logo.png',
            image1Src: '/Partners/Golden Power/1.jpg',
            image2Src: '/Partners/Golden Power/2.jpg',
            videoSrc: '/Partners/Golden Power/video.mp4',
            videoThumbnail: '/Partners/Golden Power/videoThumbnail.jpg',
            info: 'Founded in 2015, Golden Power is involved in the distribution and trade of oils and lubricants. It has multiple branches across Egyptian governorates and owns a fleet of vehicles for delivery and supply of its products. The company is currently expanding and constructing a factory for automotive oil packaging.'
        }
        ,
        {
            partnerName: 'Maka',
            buttonNumber: 7,
            logoImageUrl: '/Partners/Maka/logo.png',
            image1Src: '/Partners/Al Shorouq/1.jpg',
            image2Src: '/Partners/Al Shorouq/2.jpg',
            videoSrc: '/Partners/Al Shorouq/video.mp4',
            videoThumbnail: '/Partners/Al Shorouq/videoThumbnail.jpg',
            info: 'Info for Button 7'
        },
        {
            partnerName: 'Happy Stars',
            buttonNumber: 8,
            logoImageUrl: '/Partners/Happy Stars/logo.png',
            image1Src: '/Partners/Happy Stars/1.jpg',
            image2Src: '/Partners/Happy Stars/2.jpg',
            videoSrc: '/Partners/Happy Stars/video.mp4',
            videoThumbnail: '/Partners/Happy Stars/videoThumbnail.jpg',
            info: 'Founded in 2007, Happy Stars engages in the trade and distribution of food products. The company serves numerous clients across Egyptian governorates due to the continuous demand for food items. It also serves markets in Upper Egypt and owns a fleet of vehicles to support its operations.'
        }
        ,
        {
            partnerName: 'Arafa Truck',
            buttonNumber: 9,
            logoImageUrl: '/Partners/Arafa Truck/logo.png',
            image1Src: '/Partners/Arafa Truck/1.jpg',
            image2Src: '/Partners/Arafa Truck/2.jpg',
            videoSrc: '/Partners/Arafa Truck/video.mp4',
            videoThumbnail: '/Partners/Arafa Truck/videoThumbnail.jpg',
            info: 'Founded in 2009, Arafa Truck offers tailored solutions for all logistics operations. The company specializes in leasing and selling a wide range of equipment. Additionally, it provides maintenance services and staffing solutions to assist construction and contracting companies in their operations.'
        }
        ,
        {
            partnerName: 'Food Service',
            buttonNumber: 10,
            logoImageUrl: '/Partners/Food Service/logo.png',
            image1Src: '/Partners/Food Service/1.jpg',
            image2Src: '/Partners/Food Service/2.jpg',
            videoSrc: '/Partners/Food Service/video.mp4',
            videoThumbnail: '/Partners/Food Service/videoThumbnail.jpg',
            info: 'Founded in 2014, Food Service is involved in the production and distribution of food products. The company has multiple production lines and owns a fleet of vehicles for product distribution. Its products include white vinegar, apple vinegar, pomegranate molasses, rose water, citric acid, basmati rice, oats, and many other items.'
        }
        ,
        {
            partnerName: 'Neslo',
            buttonNumber: 11,
            logoImageUrl: '/Partners/Neslo/logo.png',
            image1Src: '/Partners/Hesham Tyres/1.jpg',
            image2Src: '/Partners/Hesham Tyres/2.jpg',
            videoSrc: '/Partners/Hesham Tyres/video.mp4',
            videoThumbnail: '/Partners/Hesham Tyres/videoThumbnail.jpg',
            info: 'Info for Button 11'
        },
        {
            partnerName: 'Heslo',
            buttonNumber: 12,
            logoImageUrl: '/Partners/Heslo/logo.png',
            image1Src: '/Partners/Heslo/1.jpg',
            image2Src: '/Partners/Heslo/2.jpg',
            videoSrc: '/Partners/Heslo/video.mp4',
            videoThumbnail: '/Partners/Heslo/videoThumbnail.jpg',
            info: 'Founded in 1995, Heslo Blinds operates numerous branches alongside its factory, specializing in various types of home automation known as smart homes. The company manufactures a wide range of automated curtains, theaters, roller shutters, conservatories, tents, garage doors, and gates in modern, classic, and trendy colors. Heslo makes home control simple and offers a complete home automation experience.'
        }
        ,
        {
            partnerName: 'International Textile',
            buttonNumber: 13,
            logoImageUrl: '/Partners/International Textile/logo.png',
            image1Src: '/Partners/International Textile/1.jpg',
            image2Src: '/Partners/International Textile/2.jpg',
            videoSrc: '/Partners/International Textile/video.mp4',
            videoThumbnail: '/Partners/International Textile/videoThumbnail.jpg',
            info: 'International Textile was founded in 2008. The company owns complete production lines for spinning, weaving, dyeing, printing, and finishing. It is known for manufacturing high-quality polyester materials and is a pioneer in the yarn and textile industry in Egypt. The company specializes in fine cotton, polyester, and blended fabrics, excelling in dyeing, printing, and finishing. It offers high-quality printing that aligns with the latest fashion trends.'
        }
        ,
        {
            partnerName: 'Metal Tech',
            buttonNumber: 14,
            logoImageUrl: '/Partners/Metal tech/logo.png',
            image1Src: '/Partners/Metal tech/1.jpg',
            image2Src: '/Partners/Metal tech/2.jpg',
            videoSrc: '/Partners/Metal tech/video.mp4',
            videoThumbnail: '/Partners/Metal tech/videoThumbnail.jpg',
            info: 'Founded in 2018, Metal Tech is involved in manufacturing, trading, and supplying electrical fittings, electrical panels, components, and cable trays of all types. The company has its own manufacturing facility and offers the ability to design various electrical panels as per consumer needs.'
        }
        ,
        {
            partnerName: 'Porsh',
            buttonNumber: 15,
            logoImageUrl: '/Partners/porsh/logo.png',
            image1Src: '/Partners/Porsh/1.jpg',
            image2Src: '/Partners/Porsh/2.jpg',
            videoSrc: '/Partners/Porsh/video.mp4',
            videoThumbnail: '/Partners/Porsh/videoThumbnail.jpg',
            info: 'Founded in 1997, Porsh Technology specializes in selling its own brand of personal computers known as GME PC, distributing computer components, designing and implementing computer networks, and providing maintenance services. The company collaborates with major computer service companies like RadioShack, Computer Shop, and Byte.'
        }
        ,
        {
            partnerName: 'Power Pack',
            buttonNumber: 16,
            logoImageUrl: '/Partners/Power Pack/logo.png',
            image1Src: '/Partners/Power Pack/1.jpg',
            image2Src: '/Partners/Power Pack/2.jpg',
            videoSrc: '/Partners/Power Pack/video.mp4',
            videoThumbnail: '/Partners/Power Pack/videoThumbnail.jpg',
            info: 'Founded in 2019, Power Pack Factory owns 7 production lines. Its products are known for high quality, made from cotton, polyester, and linen, with reinforced stitching throughout. These bags are extremely durable for daily use, offering ample capacity to easily meet your daily needs.'
        }
        ,
        {
            partnerName: 'Royal Palace',
            buttonNumber: 17,
            logoImageUrl: '/Partners/Royal Palace/logo.png',
            image1Src: '/Partners/Royal Palace/1.jpg',
            image2Src: '/Partners/Royal Palace/2.jpg',
            videoSrc: '/Partners/Royal Palace/video.mp4',
            videoThumbnail: '/Partners/Royal Palace/videoThumbnail.jpg',
            info: 'Founded in 2021, Royal Palace is a leading company in the field of manufacturing household furniture in all its forms. Established on a 25,000 square meter area in addition to large external warehouses, the factory possesses numerous machines for manufacturing all types of furniture for exhibitions, offices, and household essentials.'
        }
        ,
        {
            partnerName: 'Al Sama',
            buttonNumber: 18,
            logoImageUrl: '/Partners/Sama/logo.png',
            image1Src: '/Partners/Al Sama/1.jpg',
            image2Src: '/Partners/Al Sama/2.jpg',
            videoSrc: '/Partners/Al Sama/video.mp4',
            videoThumbnail: '/Partners/Al Sama/videoThumbnail.jpg',
            info: "Founded in 2011, Al Sama Plastic Factory specializes in manufacturing cylinders and bags from polyethylene. We also produce biodegradable bags and collaborate with French companies in the oil exploration sector. We have obtained certification from the Plastic Technology Center after conducting necessary tests to ensure the product's biodegradability under weather conditions. We have built our reputation in this field and work with major companies in the paper, metal, and plastic sectors."
        }
        ,
        {
            partnerName: 'Tyres Market',
            buttonNumber: 19,
            logoImageUrl: '/Partners/Tyre Market/logo.png',
            image1Src: '/Partners/Tyre Market/1.jpg',
            image2Src: '/Partners/Tyre Market/2.jpg',
            videoSrc: '/Partners/Tyre Market/video.mp4',
            videoThumbnail: '/Partners/Tyre Market/videoThumbnail.jpg',
            info: 'Founded in 2015, Tyre Market specializes in selling tires and batteries for cars through several branches in Greater Cairo. The company owns multiple service centers, serving major clients such as Arab Office for Contracting, Construction Company, and Fath Contracting Company.'
        }
        ,
        {
            partnerName: 'Alghandour',
            buttonNumber: 20,
            logoImageUrl: '/Partners/Bon Alghandour/logo.png',
            image1Src: '/Partners/Bon Alghandour/1.jpg',
            image2Src: '/Partners/Bon Alghandour/2.jpg',
            videoSrc: '/Partners/Bon Alghandour/video.mp4',
            videoThumbnail: '/Partners/Bon Alghandour/videoThumbnail.jpg',
            info: 'Established in 2004, Bon Alghandour specializes in grinding, packaging, and sealing coffee, in addition to serving hot beverages through multiple branches. The establishment serves various customers such as companies and institutions by supplying coffee to these facilities.'
        }
        ,
        {
            partnerName: 'Mansour',
            buttonNumber: 21,
            imageSrc: '/image5.png',
            info: 'Established in 2021, Mansour Construction Company is an engineering firm with a dynamic, knowledgeable, and enthusiastic team. They have extensive experience in managing industrial projects at an international level. Their wide range of services includes: - Design and construction (turnkey projects) - Project/Construction management - Construction safety management - Construction safety management auditing',
            logoImageUrl: '/Partners/Mansour/logo.png'
        }
        ,
        { partnerName: 'El Monsef', buttonNumber: 22, imageSrc: '/image5.png', info: 'Info for Button 22', logoImageUrl: '/Partners/El Monsef/logo.png' },
        {
            partnerName: 'MG',
            buttonNumber: 23,
            imageSrc: '/image5.png',
            info: 'Established in 2014, MG Pharma specializes in manufacturing pharmaceuticals and cosmetics for external use. The company has contracts with several companies for manufacturing its products, such as Amita for cosmetics, Pharma Zad, and Pharma Gen. MG Pharma also distributes its products to prominent companies like Pharma Over Seas and Ibn Sina Company.',
            logoImageUrl: '/Partners/MG/logo.png'
        }
        ,
        {
            partnerName: 'Urgent',
            buttonNumber: 24,
            imageSrc: '/image5.png',
            info: 'Established in 1992, Urgent Egypt for Transport and Shipping operates in the field of transporting goods and parcels using various vehicles and means of transportation. The company provides its services to major entities through several branches and service vehicles to transport goods between all governorates of the Republic.',
            logoImageUrl: '/Partners/Urgent/logo.png'
        }
        ,
        {
            partnerName: 'Master',
            buttonNumber: 25,
            imageSrc: '/image5.png',
            info: 'Established in 2017, Master for Contracting specializes in general contracting and trading building materials. The company operates multiple administrative offices to serve Egyptian governorates. It has executed construction and finishing projects in the New Administrative Capital as well as several other governorates like Sharkia and Qena.',
            logoImageUrl: '/Partners/Master/logo.png'
        }
        , {
            partnerName: "Al Ekhwa",
            buttonNumber: 26,
            imageSrc: "/image5.png",
            info: "Established in 2014, Al Ekhwa Company specializes in various types of rolls, digitally printed bags up to 6 colors, all types of plastic bags, and ready-made printed rolls. The company is a leader in the industry of plastic rolls and bags, producing heavy-duty bags for fertilizers packaging and lightweight film bags. The company exports to European Union countries, Africa, and Lebanon.",
            logoImageUrl: "/Partners/Al Akhwa/logo.png"
        }
        ,
        {
            partnerName: "Dalas",
            buttonNumber: 27,
            imageSrc: "/image5.png",
            info: "Dallas Company was established in 2020 and specializes in general contracting and the supply of pipes and sheet metal. Among the company's most important clients are the Egyptian International Group for Engineering and Contracting and Al-Diyar for Contracting. It also deals with many major companies in the same field.",
            logoImageUrl: "/Partners/Dalas/logo.png"
        }
        ,
        {
            partnerName: "First",
            buttonNumber: 28,
            imageSrc: "/image5.png",
            info: "Established in 2016, First Company has gained distinction among companies due to our integrity in the plastics industry and customer trust in our credibility. We participate in the market, engage in tenders, and contract with major companies. Our product is known for its quality and safety, and we continuously improve our quality through an excellent quality and safety system that meets ongoing challenges and customer requirements. ",
            logoImageUrl: "/Partners/First/logo.png"
        }
        ,
        {
            partnerName: "Ram",
            buttonNumber: 29,
            imageSrc: "/image5.png",
            info: "Founded in 2010, Ram Company specializes in pet food manufacturing. Since its inception, the company has partnered with Mira, the German company, as its exclusive agent for all products in Egypt and the Middle East. It has subsequently secured contracts with several distinguished brands in various pet food sectors. In 2016, Ram shifted towards manufacturing, aiming to revolutionize local production of pet food using the finest materials and suitable ingredients, ensuring exceptional care for pets owned by breeders.",
            logoImageUrl: "/Partners/Ram/logo.png"
        }
        , {
            partnerName: "Future Transport",
            logoImageUrl: "/Partners/Future/logo.png",
            buttonNumber: 30,
            imageSrc: "/image5.png",
            info: "Founded in 2013, Future Transport provides corporate employee transportation services and rents various types of vehicles for employee transport. The company owns a fleet of vehicles dedicated to its operations. Its major clients include Libtis Egypt for Pharmaceuticals and Medical Products, GIG Egypt, and Edita Food Industries."
        }
        ,
        {
            partnerName: "Samo",
            buttonNumber: 31,
            imageSrc: "/toyjoy.svg",
            info: "Founded in 2006, Samo specializes in ready-made garments, household furnishings including covers of all kinds, production of knitting and embroidery threads. Samo is known for its unique designs.",
            logoImageUrl: "/Partners/Samo/logo.png"
        }
        ,
        {
            partnerName: "Holandia",
            buttonNumber: 32,
            imageSrc: "/Holandia.svg",
            info: "Founded in 2019, Holandia White Gold is a leading company in the production of confectionery raw materials, chocolates, juice powders, and mix powder, as well as bio-gluten. The company serves various clients such as Tawfeeq Company and Al Madinah Al Munawwarah for Raw Materials.",
            logoImageUrl: "/Partners/Holandia/logo.png"
        }
        ,
        {
            partnerName: "Gebalko",
            logoImageUrl: "/Partners/Gebalko/logo.png",
            buttonNumber: 33,
            imageSrc: "/image3.png",
            info: "Established in 1984, Gebalko Egypt is one of the Egyptian companies operating in the field of contracting for over 35 years. It is registered in the records of the Egyptian Federation of Construction Contractors as a first-class contractor for integrated and finishing works. Gebalko is also registered in water and sewage networks, in addition to general works and electromechanical and electronic works.",
        }
        ,
        {
            partnerName: "Module",
            logoImageUrl: "/Partners/Module/logo.png",
            buttonNumber: 34,
            imageSrc: "/image4.png",
            info: "Founded in 2019, Module specializes in supplying food products. Among its key clients are Goodies Egypt and Infinity Dimension for Trade and Supplies."
        }
        , {
            partnerName: "Al Kanana",
            buttonNumber: 35,
            imageSrc: "/image5.png",
            info: "Founded in 2018 in Menoufia Governorate, Al Kanana Feed Company emerged as a symbol of excellence and quality in the animal feed industry. Since its inception, Al Kanana has been committed to offering high-quality products accessible to all. It is a pioneering company in the feed industry, known for its diverse range of products catering to the needs of livestock and poultry farmers. Al Kanana Feed Company employs state-of-the-art technologies in feed production, focusing on providing various concentrates for fattening livestock.",
            logoImageUrl: "/Partners/Al Kanana/logo.png"
        }
        ,
        {
            partnerName: "El Safier",
            buttonNumber: 36,
            imageSrc: "/image5.png",
            info: "Founded in 2017, El Safier specializes in the packaging and packaging of foodstuffs. It is one of the leading companies in the Egyptian market, accommodating various orders such as oil and sugar at the governorate level.",
            logoImageUrl: "/Partners/Al Safir/logo.png"
        }
        ,
        {
            partnerName: "Al Saif",
            buttonNumber: 37,
            imageSrc: "/image5.png",
            info: "Al Saif Company is a leading company with extensive experience in one of the most important fields of petroleum derivatives: importing and trading plastic materials, also known as polymers. Since its inception, Al Saif has been involved in various types of plastic materials.",
            logoImageUrl: "/Partners/Al Saif/logo.png"
        }
        , {
            partnerName: "El Khattab",
            buttonNumber: 38,
            imageSrc: "/image5.png",
            info: "Founded in 2004, El Khattab Travel specializes in transportation services for employees and staff of companies. It has multiple branches and vehicles to serve its activities. Among its key clients are City Club, Qatar National Bank in Sadat City, and Egyptian Star Tourism Company.",
            logoImageUrl: "/Partners/Khatab/logo.png"
        }
        ,
        {
            partnerName: "A M A",
            buttonNumber: 39,
            imageSrc: "/image5.png",
            info: "Founded in 2013, Ama Transport works with global companies to transport their goods, including PepsiCo for Food Industries, Pepsi Cola Egypt, Juhayna Group, and many others. The company owns a variety of vehicles capable of transporting goods and accommodating all requests for transporting products in the Egyptian market.",
            logoImageUrl: "/Partners/AMA/logo.png"
        }
        ,
        { partnerName: 'HF', logoImageUrl: '/Partners/HF/logo.png', buttonNumber: 40, imageSrc: '/image5.png', info: 'Info for Button 10' },
        {
            partnerName: "Pyramids",
            logoImageUrl: "/Partners/Pyramids/logo.png",
            buttonNumber: 41,
            imageSrc: "/image5.png",
            info: "Founded in 2020, Pyramids specializes in manufacturing and trading iron and metals. The company collaborated with a consulting office to supply and install metal canopies for the Ministry of Interior and installed canopies for Hanimex Import and Export Company. Pyramids also performed metal works at the Cairo International Stadium in the covered hall."
        }
        , {
            partnerName: "Afrofix",
            logoImageUrl: "/Partners/Afrofix/logo.png",
            buttonNumber: 42,
            imageSrc: "/image5.png",
            info: "Founded in 1981, Afrofix Trading and Engineering Company is the first Egyptian company specialized in manufacturing electromechanical support systems and spare parts for industrial, commercial, and public facilities. Its products include complete pipe support systems, strut clamps, bends, complete mechanical cladding fixation systems, concrete inserts, and steel framing systems. Its products have been used in numerous projects across Egypt, the Middle East, and Africa by major Egyptian contractors such as Orascom Construction OC, Arab Contractors, Hassan Allam, Redcon, Nafco, CCC, Contrack, and others."
        }
        ,
        {
            partnerName: 'Al Shams', buttonNumber: 43, imageSrc: '/image5.png', info: 'Info for Button 13',
            logoImageUrl: "/Partners/Alshams/logo.png"
        },
        {
            partnerName: "Power Trading",
            buttonNumber: 44,
            imageSrc: "/image5.png",
            info: "Founded in 2001, Power Trading specializes in supplying advertising and promotional materials. It has a wide range of clients.",
            logoImageUrl: "/Partners/Power Trading/logo.png"
        }
        ,
        {
            partnerName: "EverGreen",
            buttonNumber: 45,
            imageSrc: "/image5.png",
            info: "EverGreen Agricultural Contracting Company is a leading company in landscaping and green space maintenance. The company was founded in 2000 and employs a team of highly skilled agricultural engineers, managers, and agricultural workers known for their efficiency and expertise.",
            logoImageUrl: "/Partners/EverGreen/logo.png"
        }
        ,
        {
            partnerName: "Azzam",
            buttonNumber: 46,
            imageSrc: "/image5.png",
            info: "Founded in 2018, Azzam Feed Company specializes in manufacturing feeds for third parties. The company has contracts to manufacture its products with major factories such as Rawad Feed Factory. Its main products include poultry feeds and livestock feeds, each with varying concentrations.",
            logoImageUrl: "/Partners/Azzam/logo.png"
        },
        {
            partnerName: "Misr October",
            buttonNumber: 47,
            imageSrc: "/image5.png",
            info: "Founded in 2016, Misr October Feed Company engages in manufacturing all types of feeds. It also manufactures and presses all types of seeds, extracts and refines oils of all kinds, and packages them. The factory spans approximately 3,100 square meters and also owns multiple vehicles for product sales.",
            logoImageUrl: "/Partners/Msr October/logo.png"
        },
        {
            partnerName: "Material",
            buttonNumber: 48,
            imageSrc: "/image5.png",
            info: "Info for Button 18",
            logoImageUrl: "/Partners/material/logo.png"
        },
        {
            partnerName: "Mosbah",
            buttonNumber: 49,
            imageSrc: "/image5.png",
            info: "Founded in 2006, Mosbah Company specializes in trading all agricultural crops including fresh vegetables and fruits. The company has obtained ISO certification and operates numerous stores, branches, and refrigerators to preserve all vegetables and fruits across the Republic's governorates.",
            logoImageUrl: "/Partners/Mosbah/logo.png"
        },
        {
            partnerName: "AlMasa",
            buttonNumber: 50,
            imageSrc: "/image5.png",
            info: "Founded in 2013, AlMasa Packaging Materials Company operates in the field of trading food and packaging materials, distributing dry foodstuffs. It is the main supplier of packaging materials for a large number of companies such as Quedar Sweets and Al Sabeel Jadida for Poultry. Recently, the company has added a new activity in manufacturing foil dishes and food storage bags.",
            logoImageUrl: "/Partners/Almasa/logo.png"
        },
        {
            partnerName: "Vortex",
            logoImageUrl: "/Partners/Vortex/logo.png",
            buttonNumber: 51,
            imageSrc: "/image5.png",
            info: "Founded in 2020, Vortex Company operates in the advertising and marketing field. It packages products for companies such as Al Sweidi Cables, Crystal Asfour, and Egyptian Post. The company has a dedicated department for advertising and marketing printing."
        },
        {
            partnerName: "Instires",
            buttonNumber: 52,
            imageSrc: "/image5.png",
            info: "Founded in 2015, El Tayeb Trading and Supply Company specializes in trading and supplying tires and batteries. The company provides its services through several branches and retail stores in Greater Cairo and several other governorates, in addition to online sales.",
            logoImageUrl: "/Partners/Instires/logo.png"
        },
        {
            partnerName: "CIT",
            logoImageUrl: "/Partners/CIT/logo.png",
            buttonNumber: 53,
            imageSrc: "/image5.png",
            info: "Founded in 2017, CIT specializes in software development, database creation, and electronic information systems operations. The company has executed several national projects such as the Air Navigation Project, Suez Canal Authority Stadium Project, and Mansoura 1 Project in the New Administrative Capital."
        },
        {
            partnerName: "Molds Land",
            logoImageUrl: "/Partners/MoldzLand/logo.png",
            buttonNumber: 54,
            imageSrc: "/image5.png",
            info: "Moldz Land Plastic Company was established in 2017 to supply the market with various types of products. The company produces packaging for detergent, cosmetics, and disinfectants."
        },
        {
            partnerName: "Nozha",
            buttonNumber: 55,
            imageSrc: "/image5.png",
            info: "Established in 1989, Nozha Company specializes in trading medical devices, supplies, and products. The company provides healthcare services and aims to improve healthcare quality in Egypt by supplying high-quality medical equipment and supplies.",
            logoImageUrl: "/Partners/Nozha/logo.png"
        },
        {
            partnerName: "Abdo El Amir",
            buttonNumber: 56,
            imageSrc: "/image5.png",
            info: "Abdo El Amir Company was established in 2015 and is a leading company in the field of premium car care in the Arab Republic of Egypt. The company has several branches across Egypt and collaborates with several international companies.",
            logoImageUrl: "/Partners/Alamir/logo.png"
        },
        {
            partnerName: "Viochem",
            buttonNumber: 57,
            imageSrc: "/image5.png",
            info: "Established in 2012, Viochem Trading and Industry Company specializes in the production, blending, and trading of raw materials and flavor enhancers. The factory spans 3,550 square meters and also owns warehouses covering 1,400 square meters. The company serves clients such as Modern Foods, Big Cola, and October for Food Industries.",
            logoImageUrl: "/Partners/Viochem/logo.png"
        },
        {
            partnerName: "Dr Ashraf",
            buttonNumber: 58,
            imageSrc: "/image5.png",
            info: "Established in 2017, Dr. Ashraf Institution specializes in supplying medical drugs and operates a center for the sale and distribution of veterinary drugs. The institution holds all necessary licenses alongside scientific and practical experiences. Its key clients include El Zahoor Company and Fit Market.",
            logoImageUrl: "/Partners/Dr Ashraf/logo.png"
        },
        {
            partnerName: "Xpress",
            buttonNumber: 59,
            imageSrc: "/image5.png",
            info: "Express Computers Company was founded in 2013 and operates in the fields of technology and low voltage systems. The company deals with computers, laptops, surveillance cameras, theft and fire alarm systems, attendance and departure devices, telephone exchanges, intercom systems, sound systems, central satellite systems, and wired and wireless internet networks. The company is also specialized in the supply, installation, and maintenance of all networks and cables for both low and high voltage systems.",
            logoImageUrl: "/Partners/xpress/logo.png"
        },
        {
            partnerName: "Nogoum Alwady",
            buttonNumber: 60,
            imageSrc: "/image5.png",
            info: "Established in 2017 on 7,000 square meters, Nogoum Alwady Factory specializes in producing all poultry feed products. The company owns a complete transportation fleet to serve all governorates of Egypt. It has expertise in feed manufacturing, adhering to all quality standards, and relies on the best toxin-free plant materials and fungi.",
            logoImageUrl: "/Partners/Nogom Alwady/logo.png"
        }];
    const [showModal, setShowModal] = useState(false);
    const [selectedPartner, setSelectedPartner] = useState(null);

    const handleCardClick = (partner) => {
        // setSelectedPartner(partner);
        // setShowModal(true);
    };
    const [slidesToShow, setSlidesToShow] = useState(1.65);

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            const breakpoints = {
                300: 1.55,
                400: 1.65,
                500: 1.85,
                600: 2.5,
                700: 2.6,
                800: 3,
                900: 3.3,
                1000: 3.6,
            };

            // Find the matching breakpoint and set slidesToShow accordingly
            let calculatedSlidesToShow = 1;
            Object.keys(breakpoints).forEach(breakpoint => {
                if (screenWidth >= breakpoint) {
                    calculatedSlidesToShow = breakpoints[breakpoint];
                }
            });

            setSlidesToShow(calculatedSlidesToShow);
        };

        // Listen for window resize event
        window.addEventListener('resize', handleResize);

        // Initial call to set initial state
        handleResize();

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1
    };

    return (
        <div>
            <h2 className={styles.mainTitle}>
                <span style={{ color: 'white' }}>Success</span> <span style={{ color: 'var(--White-100, #EBD5A8)' }}>Partners</span>
            </h2>

            <Slider {...settings} className={styles.cardSlider}>
                {partners.map((partner, index) => (
                    // <div key={index} className={styles.cardItem} onClick={() => handleCardClick(partner)}>
                    //     <Card {...partner} />
                    // </div>
                    <div key={index} className={styles.cardItem}>
                        <Card {...partner} />
                    </div>
                ))}
            </Slider>

            {showModal && <MobModal partner={selectedPartner} onClose={() => setShowModal(false)} />}
        </div>
    );
};



export default PartnersMobile;