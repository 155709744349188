import React, { useEffect, useRef } from 'react';
import styles from './Landing.module.css';

function animateValue(ref, start, end, duration) {
    let startTimestamp = null;
    const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        const value = Math.floor(progress * (end - start) + start);
        if (ref.current && ref.current.innerHTML !== value.toString()) {
            ref.current.innerHTML = value.toString();
        }
        if (progress < 1) {
            window.requestAnimationFrame(step);
        }
    };
    window.requestAnimationFrame(step);
}

const NumberCounter = ({ start, end, duration, animate }) => {
    const ref = useRef(null);

    useEffect(() => {
        if (animate && ref.current) {
            animateValue(ref, start, end, duration);
        } else if (ref.current) {
            ref.current.innerHTML = end.toString();
        }
    }, [start, end, duration, animate]);

    return <span ref={ref}>0</span>;
};

function CompanyNumbers({ animate = false }) {
    return (
        <div className="row justify-content-start">
            <div className="col-3 pr-5">
                <div className={styles.Num}>
                    <h1>
                        <span className={styles.YellowNum}>+</span>
                        <span className={styles.WhiteNum}>
                            <NumberCounter start={0} end={14} duration={5000} animate={animate} />
                        </span>
                    </h1>
                    <h2>Years</h2>
                </div>
            </div>
            <div className="col-4">
                <div className={styles.Num}>
                    <h1>
                        <span className={styles.YellowNum}>+</span>
                        <span className={styles.WhiteNum}>
                            <NumberCounter start={0} end={500} duration={5000} animate={animate} />
                        </span>
                    </h1>
                    <h2>Happy Clients</h2>
                </div>
            </div>
            <div className="col-3">
                <div className={styles.Num}>
                    <h1>
                        <span className={styles.YellowNum}>+</span>
                        <span className={styles.WhiteNum}>
                            <NumberCounter start={0} end={80} duration={5000} animate={animate} />
                        </span>
                    </h1>
                    <h2>Partners</h2>
                </div>
            </div>
        </div>
    );
}

export default CompanyNumbers;
