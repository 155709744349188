import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './partnersMobile.module.css';


const MobModal = ({ partner, onClose }) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            const modalContent = document.querySelector('.modal-content');
            if (modalContent && !modalContent.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    return (
        <div className={styles.modaloverlay}>
            <div className='modal-content' style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <div className={`${styles.modalmob}`}>
                    <Slider2 img1={partner['Image1Url']} img2={partner['Image2Url']} video={partner['videoUrl']} videoThumbnail={partner['Image3Url']} />                    </div>
            </div>
        </div>

    );
};

function Card({ logoImageUrl, Image1Url, Image2Url, Image3Url, partnerName }) {
    return (
        <div className={styles.Card}>
            <div className={styles.logoContainer}>
                <img src={logoImageUrl} alt="Logo" className={styles.logoImage} />
            </div>
            <div className={styles.namePartner}>{partnerName}</div>
            {/* <div className={styles.cardDownImgs}>
                <img className={styles.cardImg1} src={Image1Url} alt="partner image 1" />
                <img className={styles.cardFrame1} src='/Partners/bgImgs.svg' alt="frame 1" />
                <img className={styles.cardImg2} src={Image2Url} alt="partner image 2" />
                <img className={styles.cardFrame2} src='/Partners/bgImgs.svg' alt="frame 2" />
                <img className={styles.cardImg3} src={Image3Url} alt="partner image 3" />
            </div> */}
        </div>
    );
}

const Slider2 = ({ img1, img2, video, videoThumbnail }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleClick = () => {
        // If on the last slide, loop back to the first slide
        if (currentSlide === slides.length - 1) {
            setCurrentSlide(0);
        } else {
            setCurrentSlide(currentSlide + 1);
        }
    };

    const handlePrev = () => {
        // If on the first slide, loop to the last slide
        if (currentSlide === 0) {
            setCurrentSlide(slides.length - 1);
        } else {
            setCurrentSlide(currentSlide - 1);
        }
    };
    // Define your slides array
    const slides = [
        { type: 'img', content: <img src={img1} alt="1" onClick={handleClick} style={{ width: '100%', height: '25vh' }} />, thumbnail: img1 },
        { type: 'img', content: <img src={img2} alt="2" onClick={handleClick} style={{ width: '100%', height: '25vh' }} />, thumbnail: img2 },
        { type: 'video', content: <video alt="3" style={{ width: '100%', height: '25vh' }} autoPlay loop muted controls><source src={video} type="video/mp4" /></video>, thumbnail: videoThumbnail }
    ];

    // Render only the current slide
    return (
        <div>

            {slides[currentSlide].content}

            {/* Navigation buttons */}
            <div style={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                {/* <button style={{ color: 'blanchedalmond' }} onClick={handlePrev}>Prev</button>
                <button style={{ color: 'blanchedalmond' }} onClick={handleClick}>Next</button> */}
            </div>

            {/* Thumbnails */}
            <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px', }}>
                {slides.map((slide, index) => (
                    <img
                        key={index}
                        src={slide.thumbnail}
                        alt={`Thumbnail ${index + 1}`}
                        style={{
                            width: '30%',
                            marginRight: '5px',
                            border: index === currentSlide ? '2px solid blue' : 'none',
                            filter: 'brightness(50%)' // Adjust the brightness value as needed (e.g., 50%)
                        }}
                        onClick={() => setCurrentSlide(index)}
                    />
                ))}
            </div>
        </div>
    );
};
function PartnersMobile() {

    const partners = [
        {
            partnerName: 'Toy & Joy',
            buttonNumber: 1,
            logoImageUrl: '/Partners/Toy & Joy/logo.png',
            image1Src: '/Partners/Toy & Joy/1.jpg',
            image2Src: '/Partners/Toy & Joy/2.jpg',
            videoSrc: '/Partners/Toy & Joy/video.mp4',
            videoThumbnail: '/Partners/Toy & Joy/videoThumbnail.jpg',
            info: 'تاسست شركه توي اند جوي عام ٢٠١١ و هي الشركه المتخصصه في بيع لعب الأطفال كما انه متخصص بجميع أفكار المواد التعليمية مثل الألعاب بالأرقام و الحروف لتعليم الأطفال لدي الشركه العديد من الفروع'
        },
        {
            partnerName: '2M Group',
            buttonNumber: 2,
            logoImageUrl: '/Partners/2M Group/logo.png',
            image1Src: '/Partners/2M Group/1.jpg',
            image2Src: '/Partners/2M Group/2.jpg',
            videoSrc: '/Partners/2M Group/video.mp4',
            videoThumbnail: '/Partners/2M Group/videoThumbnail.jpg',
            info: 'تاسست شركه تو ام لخدمات الطباعه عام ٢٠١١ و هي شركه تعمل في خدمات الطباعه و مستلزماتها لديها العديد من العملاء مثل شركه ارت ميديا و ميديا برنت'
        },
        {
            partnerName: 'Al Eman',
            buttonNumber: 3,
            logoImageUrl: '/Partners/Al Eman/logo.png',
            image1Src: '/Partners/Al Eman/1.jpg',
            image2Src: '/Partners/Al Eman/2.jpg',
            videoSrc: '/Partners/Al Eman/video.mp4',
            videoThumbnail: '/Partners/Al Eman/videoThumbnail.jpg',
            info: 'تم تأسيس مصنع الايمان عام 2017. يعد مصنع الايمان للبلاستيك من أكبر رواد مصانع البلاستيك في نفخ البلاستيك/ براميل/الجراكن/احدث انواع الكراسي والترابيزات بجميع الأحجام والأشكال والثقافات والاوزان والألوان والمواصفات العالمية التي تخدم جميع الصناعات.'
        },
        {
            partnerName: 'Merco Market',
            buttonNumber: 4,
            logoImageUrl: '/Partners/Merco Market/logo.png',
            image1Src: '/Partners/Al Hosam/1.jpg',
            image2Src: '/Partners/Al Hosam/2.jpg',
            videoSrc: '/Partners/Al Hosam/video.mp4',
            videoThumbnail: '/Partners/Al Hosam/videoThumbnail.jpg',
            info: 'تأسست شركة ميركو ماركت عام ٢٠١٦ وهي تعمل في نشاط تصنيع إضافات الأعلاف والأسمدة العضوية. من أهم عملائها شركة المدينة للتبادل التجاري وشركة فينوس. الشركة حاصلة على كافة التراخيص اللازمة.'
        },
        {
            partnerName: 'Al Masrya Foam',
            buttonNumber: 5,
            logoImageUrl: '/Partners/Al Masrya Foam/logo.png',
            image1Src: '/Partners/Al Masrya Foam/1.jpg',
            image2Src: '/Partners/Al Masrya Foam/2.jpg',
            videoSrc: '/Partners/Al Masrya Foam/video.mp4',
            videoThumbnail: '/Partners/Al Masrya Foam/videoThumbnail.jpg',
            info: 'تأسست شركه المصريه فوم عام ٢٠١٦ بداءت في صناعه و توزيع منتجات الأسفنج الصناعي بكافه الكثافات المختلفه ، قامت الشركه بالاستعانة بالعديد من الفنيين للعمل علي انتاج مراتب ذو جوده عاليه و تم العمل علي إنشاء ماركه اخري تحت راعيه المصريه فوم تم إطلاق اسم (كلاود بيد) و هي نوع من المراتب ذات الجوده العاليه بجانب ان المصنع يمتلك العديد من الالات و المعدات عاليه الجوده لإنتاج افضل الخامات.'
        },
        {
            partnerName: 'Golden Power',
            buttonNumber: 6,
            logoImageUrl: '/Partners/Golden Power/logo.png',
            image1Src: '/Partners/Al Mostafa/1.jpg',
            image2Src: '/Partners/Al Mostafa/2.jpg',
            videoSrc: '/Partners/Al Mostafa/video.mp4',
            videoThumbnail: '/Partners/Al Mostafa/videoThumbnail.jpg',
            info: 'تأسست شركة جولدن باور عام 2015، وهي تعمل في توزيع وتجارة الزيوت والشحوم. لديها عدة فروع في محافظات مصر، وتمتلك أسطولًا من السيارات لتوصيل وتوريد منتجاتها. حاليًا، تقوم الشركة بالتوسع وبناء مصنع لتعبئة زيوت السيارات'
        },
        {
            partnerName: 'Maka',
            buttonNumber: 7,
            logoImageUrl: '/Partners/Maka/logo.png',
            image1Src: '/Partners/Al Shorouq/1.jpg',
            image2Src: '/Partners/Al Shorouq/2.jpg',
            videoSrc: '/Partners/Al Shorouq/video.mp4',
            videoThumbnail: '/Partners/Al Shorouq/videoThumbnail.jpg',
            info: 'Info for Button 7'
        },
        {
            partnerName: 'Happy Stars',
            buttonNumber: 8,
            logoImageUrl: '/Partners/Happy Stars/logo.png',
            image1Src: '/Partners/Al Sonosy/1.jpg',
            image2Src: '/Partners/Al Sonosy/2.jpg',
            videoSrc: '/Partners/Al Sonosy/video.mp4',
            videoThumbnail: '/Partners/Al Sonosy/videoThumbnail.jpg',
            info: 'تأسست شركة هابي ستارز عام 2007، وتعمل في تجارة وتوزيع المواد الغذائية. تخدم الشركة عددًا من العملاء على مستوى محافظات مصر نظرًا للطلب الدائم على المواد الغذائية. كما تقوم الشركة بخدمة الأسواق بالوجه القبلي في محافظات مصر وتمتلك عددًا من السيارات لخدمة نشاطها.'
        },
        {
            partnerName: 'Arafa Truck',
            buttonNumber: 9,
            logoImageUrl: '/Partners/Arafa Truck/logo.png',
            image1Src: '/Partners/Arafa Truck/1.jpg',
            image2Src: '/Partners/Arafa Truck/2.jpg',
            videoSrc: '/Partners/Arafa Truck/video.mp4',
            videoThumbnail: '/Partners/Arafa Truck/videoThumbnail.jpg',
            info: 'تأسست شركة عرفة تراك عام 2009، حيث تقدم المجموعة حلولًا مخصصة لكافة أعمال اللوجستيات. الشركة تعمل في تأجير وبيع مجموعة واسعة من المعدات، بالإضافة إلى تقديم خدمات صيانة إضافية وخدمات الموظفين لمساعدة شركات المقاولات والبناء في أداء أعمالها.'
        },
        {
            partnerName: 'Food Service',
            buttonNumber: 10,
            logoImageUrl: '/Partners/Food Service/logo.png',
            image1Src: '/Partners/Food Service/1.jpg',
            image2Src: '/Partners/Food Service/2.jpg',
            videoSrc: '/Partners/Food Service/video.mp4',
            videoThumbnail: '/Partners/Food Service/videoThumbnail.jpg',
            info: 'تأسست شركة فود سيرفيس عام 2014، وتعمل في إنتاج وتوزيع المواد الغذائية. لديها العديد من خطوط الإنتاج وتمتلك أسطولًا من السيارات لتوزيع منتجاتها. من منتجاتها يشمل الخل الأبيض، التفاح، دبس الرمان، ماء الورد، حمض الليمون، أرز البسمتي، الشوفان، والعديد من المنتجات الأخرى.'
        },
        {
            partnerName: 'Neslo',
            buttonNumber: 11,
            logoImageUrl: '/Partners/Neslo/logo.png',
            image1Src: '/Partners/Hesham Tyres/1.jpg',
            image2Src: '/Partners/Hesham Tyres/2.jpg',
            videoSrc: '/Partners/Hesham Tyres/video.mp4',
            videoThumbnail: '/Partners/Hesham Tyres/videoThumbnail.jpg',
            info: 'Info for Button 11'
        },
        {
            partnerName: 'Heslo',
            buttonNumber: 12,
            logoImageUrl: '/Partners/Heslo/logo.png',
            image1Src: '/Partners/Heslo/1.jpg',
            image2Src: '/Partners/Heslo/2.jpg',
            videoSrc: '/Partners/Heslo/video.mp4',
            videoThumbnail: '/Partners/Heslo/videoThumbnail.jpg',
            info: 'تأسست شركة هيسلو بلايندز عام 1995. تمتلك الشركة العديد من الفروع بجانب المصنع وتعمل في العديد من الأنواع المختلفة من أتمتة المنزل التي تعرف باسم المنزل الذكي. تصنع الشركة مجموعة كبيرة من الستائر الآلية، والمسارح، ومصاريع الأسطوانة، والمنور، والخيام، وأبواب المرآب، والبوابات من التصاميم الحديثة والكلاسيكية والألوان العصرية. تجعل هيسلو التحكم بسيطًا وتقدم خطوطها تجربة مطلقة في أتمتة المنزل بالكامل.'
        },
        {
            partnerName: 'International Textile',
            buttonNumber: 13,
            logoImageUrl: '/Partners/International Textile/logo.png',
            image1Src: '/Partners/International Textile/1.jpg',
            image2Src: '/Partners/International Textile/2.jpg',
            videoSrc: '/Partners/International Textile/video.mp4',
            videoThumbnail: '/Partners/International Textile/videoThumbnail.jpg',
            info: 'شركة انترناشونال تكستيل تأسست عام ٢٠٠٨. تمتلك الشركة خطوط إنتاج كاملة مثل تصنيع الغزل والنسيج والصباغة والطباعة والتجهيز. الشركة تتميز بصناعة أجود الخامات البوليسترية وتعد رائدة في مجال صناعة الغزل والنسيج في مصر. كما تتخصص الشركة في صناعة أجود أقمشة القطن والبوليستر والأقمشة المخلوطة، وتتميز أيضًا بخبرتها في الصباغة والطباعة والتجهيز، وتقدم طباعة عالية الجودة تتماشى مع أحدث صيحات الموضة.'
        },
        {
            partnerName: 'Metal Tech',
            buttonNumber: 14,
            logoImageUrl: '/Partners/Metal tech/logo.png',
            image1Src: '/Partners/Metal tech/1.jpg',
            image2Src: '/Partners/Metal tech/2.jpg',
            videoSrc: '/Partners/Metal tech/video.mp4',
            videoThumbnail: '/Partners/Metal tech/videoThumbnail.jpg',
            info: 'تأسست شركه ميتال تك عام ٢٠١٨ و هي تعمل في تصنيع و تجاره و توريد المهمات الكهربائيه و اللوحات الكهربائيه و مكوناتها و حوامل الكابلات بجميع أنواعها لدي الشركه المصنع الخاص بها مع امكانيه تصميم كافه اللوحات الكهربائيه التي يحتاج لها المستهلك'
        },
        {
            partnerName: 'Porsh',
            buttonNumber: 15,
            logoImageUrl: '/Partners/porsh/logo.png',
            image1Src: '/Partners/Porsh/1.jpg',
            image2Src: '/Partners/Porsh/2.jpg',
            videoSrc: '/Partners/Porsh/video.mp4',
            videoThumbnail: '/Partners/Porsh/videoThumbnail.jpg',
            info: 'تأسست شركه بورش للتكنولوجيا عام ١٩٩٧ و هي تعمل في جميع وبيع أجهزة الكمبيوتر الشخصية التي تحمل اسمها GME PC، توزيع مكونات الكمبيوتر، تصميم وتطبيق وصيانة شبكات الحاسب الآلي، كما ان الشركه تعمل مع اكبر شركات لخدمات الكمبيوتر مثل راديو شاك، كمبيوتر شوب، بي تك.'
        },
        {
            partnerName: 'Power Pack',
            buttonNumber: 16,
            logoImageUrl: '/Partners/Power Pack/logo.png',
            image1Src: '/Partners/Power Pack/1.jpg',
            image2Src: '/Partners/Power Pack/2.jpg',
            videoSrc: '/Partners/Power Pack/video.mp4',
            videoThumbnail: '/Partners/Power Pack/videoThumbnail.jpg',
            info: 'تم تأسيس مصنع باور باك عام 2019. يمتلك المصنع 7 خطوط إنتاج. يتميز المنتج بالجودة العالية: مصنوعة من القطن والبوليستر والكتان، مع خياطة مدمجة في كل مكان، هذه الشنطة متينة للغاية للاستخدام اليومي. سعة كبيرة والتي يمكن أن تلبي بسهولة احتياجاتك اليومية.'

        },
        {
            partnerName: 'Royal Palace',
            buttonNumber: 17,
            logoImageUrl: '/Partners/Royal Palace/logo.png',
            image1Src: '/Partners/Royal Palace/1.jpg',
            image2Src: '/Partners/Royal Palace/2.jpg',
            videoSrc: '/Partners/Royal Palace/video.mp4',
            videoThumbnail: '/Partners/Royal Palace/videoThumbnail.jpg',
            info: 'تأسست شركه القصر الملكي عام ٢٠٢١ كشركه رائده في مجال صناعه الأثاث المنزلي بكافه اشكاله تأسس ذلك المصنع علي مساحه ٢٥ الف متر مربع بخلاف المخازن الكبري الخارجيه يمتلك المصنع العديد من الالات لصناعه كافه أنواع المفروشات للمعارض و المكاتب و كافه مستلزمات المنازل.'
        },
        {
            partnerName: 'Al Sama',
            buttonNumber: 18,
            logoImageUrl: '/Partners/Sama/logo.png',
            image1Src: '/Partners/Al Sama/1.jpg',
            image2Src: '/Partners/Al Sama/2.jpg',
            videoSrc: '/Partners/Al Sama/video.mp4',
            videoThumbnail: '/Partners/Al Sama/videoThumbnail.jpg',
            info: 'تأسس مصنع السما للبلاستيك عام 2011. وهو متخصص في تصنيع الأسطوانات والأكياس من البولي إيثيلين. نعمل أيضًا في صناعة الأكياس القابلة للتحلل ونتعامل مع شركات فرنسية تعمل في مجال التنقيب عن النفط. حصلنا على شهادة من مركز تكنولوجيا البلاستيك بعد إجراء الاختبارات اللازمة للمنتج والتأكد من قابليته للتحلل تحت تأثير العوامل الجوية. تمكنا من بناء سمعتنا في هذا المجال والتعامل مع كبرى الشركات في قطاعات الورق والمعادن والبلاستيك.'
        },
        {
            partnerName: 'Tyres Market',
            buttonNumber: 19,
            logoImageUrl: '/Partners/Tyre Market/logo.png',
            image1Src: '/Partners/Tyre Market/1.jpg',
            image2Src: '/Partners/Tyre Market/2.jpg',
            videoSrc: '/Partners/Tyre Market/video.mp4',
            videoThumbnail: '/Partners/Tyre Market/videoThumbnail.jpg',
            info: 'تأسست شركة تاير ماركت عام 2015، وهي تعمل في مجال بيع الإطارات والبطاريات للسيارات من خلال عدة فروع في القاهرة الكبرى. تمتلك الشركة عددًا من مراكز الخدمات، وتعد من بين أهم عملائها المكتب العربي للمقاولات، شركة بناء، وشركة الفتح للمقاولات.'
        },
        {
            partnerName: 'Alghandour',
            buttonNumber: 20,
            logoImageUrl: '/Partners/Bon Alghandour/logo.png',
            image1Src: '/Partners/Bon Alghandour/1.jpg',
            image2Src: '/Partners/Bon Alghandour/2.jpg',
            videoSrc: '/Partners/Bon Alghandour/video.mp4',
            videoThumbnail: '/Partners/Bon Alghandour/videoThumbnail.jpg',
            info: 'تأسست مؤسسة بن الغندور عام 2004، وتعمل في مجال طحن وتعبئة وتغليف البن، بالإضافة إلى تقديم المشروبات الساخنة من خلال عدة فروع. تخدم المؤسسة عددًا من العملاء مثل الشركات والمؤسسات من خلال توريد البن لهؤلاء المنشآت.'
        },
        { partnerName: 'Mansour', buttonNumber: 21, imageSrc: '/image5.png', info: 'تأسست شركه منصور للمقاولات عام ٢٠٢١ و هي شركة هندسة إنشاءات، لديها فريق ديناميكي وواسع المعرفة ومتحمس، ولديها خبرة واسعة في إدارة المشاريع الصناعية على المستوى الدولي،كما انها تغطي مجموعة واسعة من الخدمات التي تشمل: -التصميم والبناء (مشاريع تسليم المفتاح) -مشروع / إدارة البناء -إدارة سلامة البناء -تدقيق إدارة السلامة والبناء', logoImageUrl: '/Partners/Mansour/logo.png' },
        { partnerName: 'El Monsef', buttonNumber: 22, imageSrc: '/image5.png', info: 'Info for Button 22', logoImageUrl: '/Partners/El Monsef/logo.png' },
        { partnerName: 'MG', buttonNumber: 23, imageSrc: '/image5.png', info: 'تأسست شركة إم جي فارما عام 2014، وهي تعمل في مجال تصنيع الأدوية ومستحضرات التجميل للأغراض الخارجية. الشركة لديها عقود مع عدة شركات لتصنيع منتجاتها، مثل أميتا لمستحضرات التجميل، وفارما زاد، وفارما جين. كما تقوم الشركة بتوزيع منتجاتها لأبرز الشركات مثل فارما أوفر سيز وشركة ابن سينا.', logoImageUrl: '/Partners/MG/logo.png' },
        { partnerName: 'Urgent', buttonNumber: 24, imageSrc: '/image5.png', info: 'تاسست شركه ارجنت ايجيبت للنقل و الشحن عام ١٩٩٢ و هي تعمل في مجال نقل البضائع و الطرود عبر مركبات و وسائل نقل مختلفه و تقدم الشركه خدماتها للكيانات الكبري وذلك من خلال عده فروع و سيارات الخدمه لنقل جميع البضائع بين كافه محافظات الجمهوريه', logoImageUrl: '/Partners/Urgent/logo.png' },
        { partnerName: 'Master', buttonNumber: 25, imageSrc: '/image5.png', info: 'تاسست شركه ماستر للمقاولات عام ٢٠١٧ و متخصصه في القيام بأعمال المقاولات العموميه و تجاره مواد البناء ، الشركه تمتلك العديد من المقرات الاداريه لخدمه المحافظات المصريه، كما ان الشركه قامت بتنفيذ إنشاء و تشطيب عده مشاريع بالعاصمه الاداريه الجديده و ايضاً مشاريع بعده محافظات مثل محافظه الشرقيه و قنا', logoImageUrl: '/Partners/Master/logo.png' },
        { partnerName: 'Al Ekhwa', buttonNumber: 26, imageSrc: '/image5.png', info: 'تم التأسيس شركة الأخوة عام يوجد للمصنع 6 فروع متخصص في جميع أنواع الرولات، أكياس مطبوعة حتى 6 ألوان طباعة ديجيتال، جميع أنواع أكياس البلاستيك، وجميع الرولات جاهزة ومطبوعة. وتعد الشركة من الشركات الرائدة في صناعة رولات البلاستيك والأكياس، سواء في صناعة أكياس الخدمة الشاقة في تعبئة الأسمدة أو إنتاج أكياس الفيلم الخفيف. وتقوم الشركة بالتصدير إلى دول الاتحاد الأوروبي وأفريقيا ولبنان.', logoImageUrl: '/Partners/Al Akhwa/logo.png' },
        { partnerName: 'Dalas', buttonNumber: 27, imageSrc: '/image5.png', info: "تأسست شركة دالاس عام ٢٠٢٠ وهي متخصصة في مجال المقاولات العمومية وتوريد المواسير والصاج. من أهم عملاء الشركة المجموعة المصرية الدولية للهندسة والمقاولات والديار للمقاولات. كما أنها تتعامل مع العديد من الشركات الكبرى في نفس ذات المجال.", logoImageUrl: '/Partners/Dalas/logo.png' },
        {
            partnerName: 'First',
            buttonNumber: 28,
            imageSrc: '/image5.png',
            info: 'تأسست شركة فرست عام 2016، واكتسبت الأفضلية والتمييز بين الشركات بفضل نزاهتنا في صناعة البلاستيك وثقة العملاء في المصداقية. نشارك في السوق ونشارك في مناقصات ونعقد تعاقدات مع شركات كبرى. يتميز منتجنا بالجودة والأمان، ونعمل على تحسين جودة جميع أعمالنا بنظام جودة وأمان ممتاز يواكب التحديات المستمرة ويتوافق مع متطلبات العملاء. أبرز إجراءاتنا في مجال الجودة تشمل الفحص المستمر والمراقبة والصيانة الدورية لمختلف مراحل التصنيع، مع متابعة أداء كافة الموظفين بالشركة وقياس رضا عملائنا عن الخدمات التي نقدمها، واتخاذ كافة الاحتياطات اللازمة للسلامة والأمان.      ',
            logoImageUrl: '/Partners/First/logo.png'
        },
        { partnerName: 'Ram', buttonNumber: 29, imageSrc: '/image5.png', info: 'تاسست شركة رام لصناعة الأعلاف عام 2010 وهي شركة تعمل في مجال أغذية الحيوانات الأليفة. منذ بداية التأسيس، تم تعاقد الشركة مع شركة ميرا الألمانية باعتبارها الوكيل الحصري لجميع منتجاتها في مصر والشرق الأوسط. ثم توالت التعاقدات مع عدد من العلامات التجارية المتميزة في مختلف مجالات أعلاف الحيوانات الأليفة. في عام 2016، اتجهت شركة رام نحو التصنيع لتحقيق طفرة في مجال تصنيع وإنتاج الأعلاف المحلية باستخدام أجود وأفضل أنواع المواد والمكونات المناسبة التي تضمن للمربي الرعاية المتميزة للحيوان الأليف الذي يمتلكه.', logoImageUrl: '/Partners/Ram/logo.png' },
        { partnerName: 'Future Transport', logoImageUrl: '/Partners/Future/logo.png', buttonNumber: 30, imageSrc: '/image5.png', info: 'تاسست شركه فيوتشر ترانسبورت عام ٢٠١٣ تقوم الشركه بتقديم خدمات نقل العاملين بالشركات و ايجار جميع أنواع السيارات لنقل الموظفين كما ان الشركه تمتلك أسطول من السيارات لخدمه نشاطه ومن اهم عملائها شركه ليبتس ايجيبت للادويه و المستحضرات الطبيه، GIG مصر، ايديتا للصناعات الغذائيه' },
        { partnerName: 'Samo', buttonNumber: 31, imageSrc: '/toyjoy.svg', info: 'تاسست شركه سامو عام ٢٠٠٦ و هي تعمل في مجال انتاج الملابس الجاهزه بأنوعها و ايضاً صناعه المفروشات المنزليه و الاغطيه بكافه أنواعها و انتاج خيوط الحياكه و التطريز، تتميز شركه سامو بتصميمتها الخاصه', logoImageUrl: '/Partners/Samo/logo.png' },
        { partnerName: 'Holandia', buttonNumber: 32, imageSrc: '/YBG.svg', info: 'تم تأسيس شركة الهولندية وايت جولد عام 2019، وهي شركة رائدة في صناعة خامات الحلويات والشوكولاتة، ومساحيق العصائر والبودرة ميكس، وصناعة الجلوتين الحيوي، بالإضافة إلى العديد من المنتجات الأخرى. الشركة تخدم عددًا من العملاء مثل شركة التوفيق والمدينة المنورة للخامات.', logoImageUrl: '/Partners/Holandia/logo.png' },
        { partnerName: 'Gebalko', logoImageUrl: '/Partners/Gebalko/logo.png', buttonNumber: 33, imageSrc: '/image3.png', info: 'تاسست شركة جبالكو مصر عام 1984 و وهي إحدى الشركات المصرية التي تعمل في مجال المقاولات منذ أكثر من 35 عاماً حيث تم تسجيل شركة جبالكو مصر فى سجلات الأتحاد المصرى لمقاولى التشييد و البناء كفئة أولى لأعمال المتكاملة و الأعمال التكميلية و كذلك تم تسجيلها فى شبكات المياة و الصرف علاوة على أعمال الأشغال العامة و الأعمال الكهروميكانيكية و الألكترونية.' },
        { partnerName: 'Module', logoImageUrl: '/Partners/Module/logo.png', buttonNumber: 34, imageSrc: '/image4.png', info: 'تم تأسيس شركة موديول عام 2019، وهي متخصصة في توريد المواد الغذائية. من بين أهم عملائها شركتي جوديز ايجيبت وإنفينيتي دايمنشن للتجارة والتوريدات.' },
        { partnerName: 'Al Kanana', buttonNumber: 35, imageSrc: '/image5.png', info: 'تأسست شركة الكنانه للأعلاف عام 2018 في محافظة المنوفية، حيث نشأت هذه الشركة كرمز للتميز والجودة في صناعة الأعلاف. ومنذ تأسيسها، حرصت شركة الكنانة على تقديم منتجات عالية الجودة وفي متناول الجميع. تعتبر شركة أعلاف الكنانة من الرواد في صناعة الأعلاف، حيث تمتاز بتقديم مجموعة متنوعة من المنتجات التي تلبي احتياجات جميع أصحاب المزارع، سواء كانوا مربين للماشية، الدواجن. تعتمد شركة أعلاف الكنانة على أحدث التقنيات في إنتاج الأعلاف حيث تعمل الشركة على توفير علف مواشي تسمين مختلفة التركيز.', logoImageUrl: '/Partners/Al Kanana/logo.png' },
        {
            partnerName: 'El Safier', buttonNumber: 36,
            imageSrc: '/image5.png',
            info: 'تأسست شركه السفير عام ٢٠١٧ و هي تعمل في نشاط تعبئه و تغليف المواد الغذائيه و هي من الشركات الرائده بالسوق المصري لكونها تستوعب العديد من الطلبات مثل الزيت و السكر علي مستوي المحافظات',
            logoImageUrl: '/Partners/Al Safir/logo.png'
        },
        { partnerName: 'Al Saif', buttonNumber: 37, imageSrc: '/image5.png', info: 'شركة السيف هى شركة رائدة ولها باع طويل وسنوات من الخبرة فى إحدى أهم مجالات مشتقات البترول وهو مجال إستيراد وتجارة خامات البلاستيك أو كما يطلق عليه اللدائن. شركة السيف تعمل منذ بداياتها فى خامات البلاستيك بكل أنواعها', logoImageUrl: '/Partners/Al Saif/logo.png' },
        { partnerName: 'El Khattab', buttonNumber: 38, imageSrc: '/image5.png', info: 'تاسست شركه الخطاب ترافل عام ٢٠٠٤ و هي شركه لخدمات نقل العاملين و الموظفين بالشركات لديها العديد من الفروع و السيارات لخدمه النشاط من اهم العملاء نادي سيتي كلوب، اداره البنك الاهلي القطري بالسادات، شركه إيجيبشين ستار للسياحه', logoImageUrl: '/Partners/Khatab/logo.png' },
        { partnerName: 'A M A', buttonNumber: 39, imageSrc: '/image5.png', info: 'تاسست شركه Ama للنقل عام ٢٠١٣ و هي تعمل مع شركات عالميه لنقل بضائعها مثل شركه شيبسي للصناعات الغذائيه، شركه بيبسي كولا مصر، مجموعه شركات جهينه و العديد من الشركات الاخري، كما ان الشركه تمتلك سيارات متنوعه للقدره علي نقل البضائع و استيعاب كافه طلبات و نقل منتجات السوق المصري', logoImageUrl: '/Partners/AMA/logo.png' },
        { partnerName: 'HF', logoImageUrl: '/Partners/HF/logo.png', buttonNumber: 40, imageSrc: '/image5.png', info: 'Info for Button 10' },
        { partnerName: 'Pyramids', logoImageUrl: '/Partners/Pyramids/logo.png', buttonNumber: 41, imageSrc: '/image5.png', info: 'تاسست شركه بيراميدز لتصنيع و تجاره الحديد و المعادن عام ٢٠٢٠ قامت الشركه بالتعاون مع مكتب استشاري لتوريد و تركيب جمالونات معدنيه لوزاره الداخليه و تركيب جمالونات لشركه هانيمكس للاستيراد و التصدير و قامت الشركه بالأعمال المعدنيه باستاد القاهره الدولي بالصاله المغطاه' },
        { partnerName: 'Afrofix', logoImageUrl: '/Partners/Afrofix/logo.png', buttonNumber: 42, imageSrc: '/image5.png', "info": "تأسست الشركة الأفريقية للتجارة والهندسة (AFROFIX) عام 1981، وهي أول شركة مصنعة في مصر متخصصة في إنتاج أنظمة الدعم الكهروميكانيكية وقطع الغيار للمنشآت الصناعية والتجارية والمرافق العامة. تشمل منتجاتها أنظمة دعم الأنابيب الكاملة، ومشابك العوارض، والأقواس، وأنظمة تثبيت الكسوة الميكانيكية الكاملة، والمدخلات الخرسانية، ونظام الإطارات الفولاذية. تم استخدام منتجاتها في العديد من المشاريع في مصر والشرق الأوسط وأفريقيا من قبل مقاولين مصريين رئيسيين مثل أوراسكوم للإنشاءات OC، المقاولون العرب، حسن علام، ريدكون، نافكو، سي سي سي، كونتراك، وغيرهم. الشركة معتمدة من قبل العديد من دور الاستشارات الهندسية المرموقة مثل دار الهندسة، مجموعة الدكتور شاكر الاستشارية، ECG، صبور، rmc وغيرها." },
        {
            partnerName: 'Al Shams', buttonNumber: 43, imageSrc: '/image5.png', info: 'Info for Button 13',
            logoImageUrl: '/Partners/Alshams/logo.png'
        },
        {
            partnerName: 'Power Trading', buttonNumber: 44, imageSrc: '/image5.png', imageSrc: '/image5.png',
            info: 'تاسست شركه باور تريدنج عام ٢٠٠١ و هي تعمل في توريدات مستلزمات الدعايه و الإعلان لديها العديد من جهات التعامل',
            logoImageUrl: '/Partners/Power Trading/logo.png'
        },
        {
            partnerName: 'EverGreen', buttonNumber: 45, imageSrc: '/image5.png', info: 'تعتبر شركة إيفر جرين للمقاولات الزراعية من الشركات الرائدة في مجال تنسيق الحدائق وصيانة المساحات الخضراء. تأسست الشركة عام 2000. يعمل بها نخبة من المهندسين المحترفين والإداريين الزراعيين والعمالة الزراعية على أعلى مستوى من الكفاءة والخبرة.'
            , logoImageUrl: '/Partners/EverGreen/logo.png'
        },
        { partnerName: 'Azzam', buttonNumber: 46, imageSrc: '/image5.png', info: 'شركة عزام للأعلاف تأسست عام ٢٠١٨ وتعمل في نشاط تصنيع الأعلاف للأطراف الثالثة، وتمتلك الشركة عقود لتصنيع منتجاتها مع مصانع كبرى مثل مصنع الرواد لصناعة الأعلاف. من منتجات الشركة الرئيسية أعلاف الدواجن وأعلاف المواشي، مع اختلاف نسب التركيز لكل نوع من أنواع الأعلاف.', logoImageUrl: '/Partners/Azzam/logo.png' },
        { partnerName: 'Misr October', buttonNumber: 47, imageSrc: '/image5.png', info: 'شركة مصر اكتوبر للأعلاف تأسست عام ٢٠١٦ وتعمل في نشاط تصنيع وإنتاج كافة أنواع الأعلاف وأيضًا تصنيع وإنتاج وعصر كافة أنواع البذور واستخلاص وتكرير الزيوت بكافة أنواعها وتعبئتها، علماً بأن المصنع على مساحة ٣١٠٠ متر مربع تقريباً وأيضًا تمتلك العديد من السيارات لخدمة النشاط في بيع المنتجات.', logoImageUrl: '/Partners/Msr October/logo.png' },
        { partnerName: 'Material', buttonNumber: 48, imageSrc: '/image5.png', info: 'Info for Button 18', logoImageUrl: '/Partners/material/logo.png' },
        { partnerName: 'Mosbah', buttonNumber: 49, imageSrc: '/image5.png', info: 'تأسست شركه مصباح عام ٢٠٠٦ و هي تعمل في تجاره جميع المحاصيل الزراعيه من الخضار و الفاكهه الطازجه حصلت الشركه علي شهاده ال iso كما أنها لديها العديد من المحلات و الفروع و الثلاجات لحفظ جميع الخضار و الفاكهه علي مستوي محافظات الجمهوريه', logoImageUrl: '/Partners/Mosbah/logo.png' },
        { partnerName: 'AlMasa', buttonNumber: 50, imageSrc: '/image5.png', info: 'تأسست شركة ألماسة لمواد التعبئة والتغليف عام 2013، وتعمل في مجال تجارة المواد الغذائية والتغليف وتوزيع مواد غذائية جافة. تعد المورد الرئيسي لمواد التعبئة والتغليف لعدد كبير من الشركات مثل قويدر للحلويات والسبيل الجديدة للدواجن. مؤخرًا، قامت الشركة بإضافة نشاط جديد في تصنيع أطباق وعبوات الفويل وأكياس لحفظ الطعام.', logoImageUrl: '/Partners/Almasa/logo.png' },
        { partnerName: 'Vortex', logoImageUrl: '/Partners/Vortex/logo.png', buttonNumber: 51, imageSrc: '/image5.png', info: 'تأسست شركة فورتكس عام 2020، وتعمل في مجال الدعاية والإعلان. تعمل على تغليف منتجات شركات مثل شركة السويدي للكابلات وشركة كريستال عصفور والبريد المصري. لدى الشركة قسم خاص بطباعة الدعايات والإعلانات.    ' },
        {
            partnerName: 'Instires', buttonNumber: 52, imageSrc: '/image5.png', info: 'تاسست شركه الطيب للتجاره و التوريد عام ٢٠١٥ و هي تعمل بتجارة و توريد الإطارات و البطاريات كما ان الشركه تقدم خدماتها من خلال عده مقرات و فروع و محلات تجزئه بالقاهره الكبري و عده محافظات اخري بالاضافه الي البيع عبر منصات الإنترنت'
            , logoImageUrl: '/Partners/Instires/logo.png'
        },
        { partnerName: 'CIT', logoImageUrl: '/Partners/CIT/logo.png', buttonNumber: 53, imageSrc: '/image5.png', info: 'تأسست شركه سي اي تي عام ٢٠١٧ و هي متخصصه في أعمال انتاج و تطوير البرامج و التطبيقات و إنشاء قواعد البيانات و نظم المعلومات الالكترونيه و تشغيلها كما ان الشركه نفذت العديد من المشاريع القوميه مثل مشروع الملاحه الجويه، مشروع ستاد هيئه قناه السويس و مشروع المنصوره ١ بالعاصمه الاداريه' },
        { partnerName: 'Molds Land', logoImageUrl: '/Partners/MoldzLand/logo.png', buttonNumber: 54, imageSrc: '/image5.png', info: 'شركة مولدز لاند للبلاستيك نشأت عام 2017 وتقوم بتزويد السوق بأنواع مختلفة من المنتجات. الشركة تقوم بإنتاج عبوات لمجالات المنظفات ومستحضرات التجميل والمطهرات.' },
        { partnerName: 'Nozha', buttonNumber: 55, imageSrc: '/image5.png', info: 'تأسست شركة النزهة عام 1989، وتعمل في تجارة الأجهزة والمستلزمات والمستحضرات الطبية. تقدم الشركة خدمات الرعاية الصحية وتسعى إلى تحسين جودة الرعاية الصحية في مصر من خلال توفير معدات طبية عالية الجودة ومستلزمات طبية.', logoImageUrl: '/Partners/Nozha/logo.png' },
        { partnerName: 'Abdo El Amir', buttonNumber: 56, imageSrc: '/image5.png', info: 'تم تأسيس شركة عبده الأمير عام 2015، وهي شركة رائدة في مجال العناية الفائقة بالسيارات في جمهورية مصر العربية. تمتلك الشركة عدة فروع على مستوى محافظات مصر وتتعامل مع العديد من الشركات العالمية.', logoImageUrl: '/Partners/Alamir/logo.png' },
        { partnerName: 'Viochem', buttonNumber: 57, imageSrc: '/image5.png', info: 'تم تأسيس شركة فيو كيم للتجارة والصناعة عام 2012، وهي تختص في إنتاج وخلط وتجارة الخامات والمكسبات للطعم والرائحة. يقع المصنع على مساحة 3550 متر مربع وتمتلك أيضًا مخازن بمساحة 1400 متر مربع. الشركة تخدم عددًا من العملاء مثل شركة مودرن فودز، وشركة بيج كولا، وشركة أكتوبر للصناعات الغذائية.', logoImageUrl: '/Partners/Viochem/logo.png' },
        { partnerName: 'Dr Ashraf', buttonNumber: 58, imageSrc: '/image5.png', info: 'نشأت موسسه دكتور اشرف عام ٢٠١٧ و هي متخصصه في توريد الادويه الطبيه و مركز بيع و تداول الادويه و اللقاحات البيطريه لدي المؤسسه كافه التراخيص المطلوبه بجانب الخبرات العلميه و العمليه التي تعمل بالمؤسسة و من اهم عملائها شركه الزهور و شركه فيت ماركت', logoImageUrl: '/Partners/Dr Ashraf/logo.png' },
        { partnerName: 'Xpress', buttonNumber: 59, imageSrc: '/image5.png', info: 'تأسست شركه اكسبريس للحاسبات عام ٢٠١٣ و هي تعمل بمجال التكنولوجيا و التيار الخفيف و اجهزه كمبيوتر و لاب توب و كاميرات مراقبه و اجهزه انذار سرقه و حريق و اجهزه حضور و انصراف و سنترالات و انتركم و ساوند سيستم و دش مركزي و شبكات انترنت سلكي ولاسلكي كما أن الشركه أيضا متخصصه في توريد و تركيب و صيانه جميع الشبكات و الاسلاك للتيار الخفيف و العالي', logoImageUrl: '/Partners/Xpress/logo.png' },
        { partnerName: 'Nogoum Alwady', buttonNumber: 60, imageSrc: '/image5.png', info: 'تأسس مصنع نجوم الوادي عام ٢٠١٧ حيث نشأ المصنع على ٧٠٠٠ متر مربع لإنتاج وتصنيع جميع منتجات أعلاف الدواجن. الشركة تمتلك أسطول نقل كامل لخدمة جميع محافظات مصر، وتتمتع بخبرة في تصنيع الأعلاف مع الالتزام بكافة معايير الجودة، وتعتمد على أفضل المواد النباتية الخالية من السموم والفطريات.', logoImageUrl: '/Partners/Nogom Alwady/logo.png' },
    ];
    const [showModal, setShowModal] = useState(false);
    const [selectedPartner, setSelectedPartner] = useState(null);

    const handleCardClick = (partner) => {
        // setSelectedPartner(partner);
        // setShowModal(true);
    };
    const [slidesToShow, setSlidesToShow] = useState(1.65);

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            const breakpoints = {
                300: 1.55,
                400: 1.65,
                500: 1.85,
                600: 2.5,
                700: 2.6,
                800: 3,
                900: 3.3,
                1000: 3.6,
            };

            // Find the matching breakpoint and set slidesToShow accordingly
            let calculatedSlidesToShow = 1;
            Object.keys(breakpoints).forEach(breakpoint => {
                if (screenWidth >= breakpoint) {
                    calculatedSlidesToShow = breakpoints[breakpoint];
                }
            });

            setSlidesToShow(calculatedSlidesToShow);
        };

        // Listen for window resize event
        window.addEventListener('resize', handleResize);

        // Initial call to set initial state
        handleResize();

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1
    };

    return (
        <div>
            <h2 className={styles.mainTitle} style={{ display: 'flex', flexDirection: 'row-reverse', paddingRight: '.5rem', paddingBottom: '1.5rem' }}>
                <span style={{ fontFamily: 'Cairo Light', marginRight: "0", marginLeft: '16px', textAlign: 'right', color: 'white' }}   >شركــاء </span> <span style={{ fontFamily: 'Cairo Light', marginRight: "0", marginLeft: '16px', textAlign: 'right', color: 'var(--White-100, #EBD5A8)' }}>النجــاح</span>
            </h2>

            <Slider {...settings} className={styles.cardSlider}>
                {partners.map((partner, index) => (
                    // <div key={index} className={styles.cardItem} onClick={() => handleCardClick(partner)}>
                    //     <Card {...partner} />
                    // </div>
                    <div key={index} className={styles.cardItem} >
                        <Card {...partner} />
                    </div>
                ))}
            </Slider>

            {showModal && <MobModal partner={selectedPartner} onClose={() => setShowModal(false)} />}
        </div>
    );
};



export default PartnersMobile;